// @ts-nocheck
import Landinglayouts from '../../layouts/Landinglayouts';
import Features from './components/Features';
import Herosection from './components/Herosection';
import Howitwork from './components/Howitwork';
import Landingcar from './components/Landingcar';

function Home() {
    return (
        <Landinglayouts title='Landing page'>
            <Herosection />
            <Features />
            <Landingcar />
            <Howitwork />
        </Landinglayouts>
    )
}

export default Home
