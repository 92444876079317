import { ReactNode } from 'react'
import DashboardSidebar from '../partials/Sidebar/DashboardSidebar'
import Navbardashboard from '../partials/Navbar/Navbardashboard'

type Props = {
    title: string,
    children?: ReactNode
}

function Dashboardlayout({ title, children }: Props) {
    return (
        <div className='flex overflow-hidden gap-0 bg-primary'>
            <aside className='h-screen sticky top-0 z-50'>
                <DashboardSidebar />
            </aside>
            <main className="max-w-full bg-white flex-1 mx-auto overflow-hidden">
                <Navbardashboard title={title} />
                <div className='px-7 py-4'>
                    {children ? children : null}
                </div>
            </main>
        </div>
    )
}

export default Dashboardlayout