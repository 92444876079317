import { Chart } from 'primereact/chart';
import { useEffect, useState } from "react";
import { FaFingerprint } from 'react-icons/fa';
import { FcAutomotive, FcBusinessman, FcNews } from "react-icons/fc";
import { useQuery } from "react-query";
import StatisticCard from '../../../components/Cards/StatisticCard';
import { appConfig } from "../../../contants/configurations";
import IMAGES from '../../../contants/images';
import axiosInstance from "../../../utils/axiosInstance";
import { Dashboardlayout } from "../../index";

function Homedashboard() {

    const { data } = useQuery('fetchStatistiques', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/home`);
        return data
    })

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const statistics = [
        {
            title: 'Utilisateurs',
            count: data?.users,
            icon: <FcBusinessman className="text-blue-600" size={40} />
        },
        {
            title: 'Reservations',
            count: data?.reservation,
            icon: <FaFingerprint className="text-blue-600" size={40} />
        },
        {
            title: 'Marques',
            count: data?.marques,
            icon: <FcNews className="text-blue-600" size={40} />
        },
        {
            title: 'Véhicules',
            count: data?.cars,
            icon: <FcAutomotive className="text-blue-600" size={40} />
        }
    ]

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août'],
            datasets: [
                {
                    label: 'Réservation',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [65, 59, 80, 81, 76, 55, 80, 5]
                },
                {
                    label: 'Contrat',
                    backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    data: [28, 48, 40, 19, 66, 27, 80, 5]
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);


    return (
        <Dashboardlayout title="Dashboard">
            <div className='h-full flex flex-col gap-4'>
                <div className='grid grid-flow-row gap-3 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    {
                        statistics.map((item, key) => {
                            return <StatisticCard
                                key={key}
                                title={item.title}
                                icon={item.icon}
                                count={item.count}
                            />
                        })
                    }
                </div>

                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='bg-white w-full md:w-1/2 md:h-96 rounded-md flex items-center justify-center py-4 overflow-hidden'>
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" />
                    </div>
                    <div className='bg-white w-full md:w-1/2 md:h-96 rounded-md flex justify-center items-center py-4 overflow-hidden'>
                        <div className='p-8'>
                            <img src={IMAGES.CAR} alt="Car" className='w-full object-contain' />
                        </div>
                    </div>
                </div>
            </div>
        </Dashboardlayout>
    )
}

export default Homedashboard