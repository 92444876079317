import axios from "axios";
import { appConfig } from "../contants/configurations";

const token = localStorage.getItem("accessToken");

const axiosInstance = axios.create({
    baseURL: appConfig.API_URL,
    withCredentials: true,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        
    },
})

export default axiosInstance