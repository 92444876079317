import { ThemeProvider, createTheme } from "@mui/material/styles";
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Caredit, CarsList, Carsadd, ConditionsGenerale, Contract, ContractList, Contractadd, Contractedit, ForgotPassword, Home, Homedashboard, Login, Loginchoose, MarqueList, ModelList, NewPassword, Notfound, PolitiqueConfidentialite, RentCar, Rental, RentalDetails, ReservationList, UserList } from './pages';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: "#4285f4",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            borderRadius: 8,
            textTransform: "capitalize"
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            borderRadius: 8,
          }),
        }),
      },
    },
  },
})

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='/' element={<Home />}></Route>
           <Route path='*' element={<Notfound />}></Route>
          <Route path='/login' element={<Loginchoose />}></Route>
          <Route path='/admin' element={<Login />}></Route>
          <Route path='/forgot-password' element={<ForgotPassword />}></Route>
          <Route path='/new-password' element={<NewPassword />}></Route>
          <Route path='/rental' element={<Rental />}></Route>
          <Route path='/rental/:id' element={<RentalDetails />}></Route>
          <Route path='/rent_my_car' element={<RentCar />}></Route>
          <Route path='/terms-of-service' element={<ConditionsGenerale />}></Route>
          <Route path='/privacy-policy' element={<PolitiqueConfidentialite />}></Route> 
          {/* Admin Routes */}
          <Route path='/dashboard' element={<Homedashboard />}></Route>
          <Route path='/users' element={<UserList />}></Route>
          <Route path='/marque' element={<MarqueList />}></Route>
          <Route path='/model' element={<ModelList />}></Route>
          <Route path='/cars' element={<CarsList />}></Route>
          <Route path='/cars/add' element={<Carsadd />}></Route>
          <Route path='/cars/edit/:id' element={<Caredit />}></Route>
          <Route path='/contrat' element={<ContractList />}></Route>
          <Route path='/contrat/add' element={<Contractadd />}></Route>
          <Route path='/contrat/edit/:id' element={<Contractedit />}></Route>
          <Route path='/contrat/generate/:id' element={<Contract />}></Route>
          <Route path='/reservation' element={<ReservationList />}></Route> 
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
