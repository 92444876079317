import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import IMAGES from '../contants/images';
import Footer from '../partials/Footer/Footer';
import Navbar from '../partials/Navbar/Navbar';

type Props = {
    title: string,
    children?: React.ReactNode
}

function Landinglayouts({ title, children }: Props) {
    return (
        <>
            <Navbar />
            <div>
                {children}
            </div>
            <FloatingWhatsApp accountName='STS'
                phoneNumber='+221774462370'
                notification={true}
                notificationSound={true}
                chatMessage='Salut, comment pouvons nous vous aider ?'
                placeholder='Entrer votre message...'
                statusMessage='Nous sommes à votre service'
                avatar={IMAGES.LOGO2}
            />
            <Footer />
        </>
    )
}

export default Landinglayouts
