// @ts-nocheck
import SearchForm from '../../../components/Cards/SearchForm'
import style from "./hero.module.css"

function Herosection() {
    return (
        <div className={`${style.hero} h-[90vh] md:h-[100vh] flex flex-col justify-end pt-24`}>
            <div className='flex flex-col md:flex-row h-ful px-4 md:px-28 gap-8 md:gap-8 md:bg-lack/60 py-12 md:py-7 rounded-t-lg'>
                <div className='w-full md:w-1/2 flex items-end h-full md:-translate-y-[10px]'>
                    <div className='flex flex-col gap-1 md:mt-2'>
                        <span className=' text-slate'>SERVICE DE LOCATION DE VEHICULES </span>
                        <div className='text-white text-xl md:text-3xl'>
                            <h2 className='md:leading-[50px]'>Bienvenue chez</h2>
                            <h2 className='leading-[60px]'>SÉNÉGAL <span className='text-green-400 text-xl'>TRANSPORT</span> SOLUTIONS</h2>
                        </div>
                        <div className='text-gray-100'>
                            Nous mettons à votre disposition nos véhicules et/ou chauffeurs pour une expérience de mobilité et déplacements inoubliable.
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 h-full flex flex-col items-end justify-end md:pl-10'>
                    <div className='w-full md:translatey-[65px]'>
                        <SearchForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Herosection