// @ts-ignore 
// @ts-nocheck
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { appConfig } from '../../../contants/configurations';
import axiosInstance from "../../../utils/axiosInstance";
import Dashboardlayout from './../../../layouts/Dashboardlayout';

let emptyModel = {
    id: '',
    name: '',
    marque: '',
}

function ModelList() {
    const [formValues, setFormValues] = useState(emptyModel)
    const [modalTitle, setModalTitle] = useState<string | undefined>('Nouveau Modèle')
    const [typemodal, setTypemodal] = useState<boolean | undefined>(false);

    const [selectedEmploye, setSelectedEmploye] = useState<[] | null>(null);
    // @ts-ignore
    const [rowClick, setRowClick] = useState<boolean>(true);
    const [employeDialog, setEmployeDialog] = useState(false);
    const toast = useRef<Toast>(null)
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const { isLoading, error, data, refetch } = useQuery('fetchModels', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/model`);
        return data.data;
    })

    const marques = useQuery('fetchMarques', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/marques`);
        return data.data
    })

    if (error) {
        return <span>Error ...</span>
    }


    const handleMarqueSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    // TO add new Model
    const handleModelAddInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const saveModel = async () => {
        await axiosInstance.post(`${appConfig.API_URL}/model`, formValues).then((response) => {
            toast.current?.show({ severity: 'success', summary: 'Enregistré', detail: response.data.message, life: 8000 });
            setFormValues(emptyModel);
            setEmployeDialog(false)
            refetch()
        }).catch((error) => {
            Swal.fire(
                'Sorry!',
                error.response.data.message,
                'error')
            toast.current?.show({ severity: 'warn', summary: error.response.data.message, detail: 'Erreur Inconnu', life: 5000 });
            setEmployeDialog(false)
        })
    }

    const updateModel = () => {
        axiosInstance.put(`${appConfig.API_URL}/model/${formValues.id}`, formValues).then(response => {
            toast.current?.show({ severity: 'success', summary: 'Enregistré', detail: response.data.message, life: 8000 });
            setFormValues(emptyModel);
            setEmployeDialog(false)
            refetch()
        })
            .catch((error) => {
                toast.current?.show({ severity: 'warn', summary: error.response.data.message, detail: 'Erreur Inconnu', life: 5000 });
            });
    }

    // To edit an Category
    const editEmploye = (RowData: any) => {
        setModalTitle('Modifier les Informations')
        setEmployeDialog(true)
        setTypemodal(true);
        // alert(JSON.stringify(RowData.marque));
        setFormValues({
            ...formValues,
            id: RowData._id,
            name: RowData.name,
            marque: RowData.marque[0].name,
        })
    }

    // ####################### For Add Dialog
    const openAddEmployeDialog = () => {
        setModalTitle('Nouveau Modèle')
        setEmployeDialog(true);
    };

    const hideAddEmployeDialog = () => {
        setEmployeDialog(false);
        setTypemodal(false)
        setFormValues(emptyModel);
    };

    const enterpriseAddDialogFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideAddEmployeDialog} />
            <Button label="Enregistrer" icon="pi pi-check" onClick={saveModel} />
        </React.Fragment>
    );

    const enterpriseEditDialogFooter = (
        <React.Fragment>
            <Button label="Annuler" icon="pi pi-times" outlined onClick={hideAddEmployeDialog} />
            <Button label="Modifier" icon="pi pi-check" onClick={updateModel} />
        </React.Fragment>
    );

    const confirmDelete = async (id: string) => {
        await Swal.fire({
            title: 'Voulez-vous vraiment le supprimer ?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`${appConfig.API_URL}/model/${id}`).then(response => {
                    Swal.fire(
                        'Supprimé!',
                        response.data.message,
                        'success'
                    )
                    refetch()
                });
            }
        })

    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };
        // @ts-ignore
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // Toolbar
    // --- Left side
    const startContent = (
        <React.Fragment>
            <Button label="Nouveau" size='small' icon="pi pi-plus" onClick={openAddEmployeDialog} className="mr-2 bg-primarydeep h-10 border-none" />
            <Button label="Supprimer" size='small' icon="pi pi-trash" className='h-10' severity="danger" disabled={!selectedEmploye || !selectedEmploye.length} />
        </React.Fragment>
    );

    const endContent = (
        <React.Fragment>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} className='text-sm' placeholder="Rechercher..." />
            </span>
        </React.Fragment>
    );

    const actionButtons = (RowData: any) => {
        return <div>
            <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editEmploye(RowData)} />
            <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(RowData._id)}
            />
        </div>
    }

    const displayMarque = (rowData: any) => {
        return <span>{rowData?.marque[0]?.name}</span>;
    }

    return (
        <Dashboardlayout title="Liste des Modèles">
            <div className='h-full'>
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="shadow bg-white rounded-lg p-8 flex flex-col gap-3">
                    <Toolbar start={startContent} end={endContent} />
                    {
                        isLoading ? <ProgressSpinner /> :
                            <DataTable value={data} selectionMode={rowClick ? undefined : 'multiple'} size='small'
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                paginator
                                rows={5}
                                dataKey="_id"
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                filters={filters} filterDisplay="menu"
                                globalFilterFields={['name', 'email', 'roles', 'status']}
                                emptyMessage="Aucune catégorie disponibles."
                                tableStyle={{ minWidth: '40rem' }}>
                                <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
                                <Column field="name" sortable header="Libellé"></Column>
                                <Column field="marque" sortable body={displayMarque} header="Marque"></Column>
                                <Column header="Action" body={actionButtons}></Column>
                            </DataTable>
                    }
                </div>

                {/* ################ ==================== ############################
                    // --------------- Modal To Add or Edit Modèle ---------------------- */}
                <Dialog visible={employeDialog} style={{ width: '50vw' }
                } breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={modalTitle} modal className="p-fluid" onHide={hideAddEmployeDialog} footer={typemodal ? enterpriseEditDialogFooter : enterpriseAddDialogFooter} >

                    <div className='flex flex-col gap-4'>
                        <div className="flex flex-row flex-wrap gap-4" style={{ gap: 32 }}>

                            <div className="w-full flex flex-col gap-2">
                                <label htmlFor="roles" className="font-medium">
                                    Marque
                                </label>
                                <Dropdown value={formValues.marque} onChange={handleMarqueSelect}
                                    defaultValue={formValues.marque} options={marques.data}
                                    name='marque'
                                    optionLabel='name'
                                    optionValue='_id'
                                    editable={true}
                                    placeholder="Choisir" className="w-full md:w-14rem" 
                                    />
                            </div>

                            <div className="w-full flex flex-col gap-2" style={{marginTop:20                                      }}>
                                <label htmlFor="name" className="font-medium">
                                    Modèle
                                </label>
                                <InputText name="name" value={formValues.name} onChange={handleModelAddInput} />
                            </div>

                        </div>
                    </div>

                </Dialog >
            </div>
        </Dashboardlayout>
    )
}

export default ModelList