// @ts-ignore 
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { Button, FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const locationType = [
    {
        label: "Location de voiture sans chauffeur"
    },
    {
        label: "Location de voiture avec chauffeur"
    },
    {
        label: "Trajets simple vers/depuis l'aéroport AIBD"
    },
]

const villes = [
    { title: 'Dakar' },
    { title: 'Mbour-Saly' },
    { title: 'Cap Skirring' },
    { title: 'Thiès' },
    { title: 'Touba' },
    { title: 'Saint Louis' },
    { title: 'Diourbel' },
    { title: 'Kaolack' },
    { title: 'Ziguinchor' },
    { title: 'Kédougou' },
    { title: 'Tambacounda' },
    { title: 'Matam' },
    { title: 'Louga' },
    { title: 'Kolda' },
    { title: 'Séhdiou' },
    { title: 'Kaffrine' },
];

function SearchForm() {

    const navigate = useNavigate();

    const [type, setType] = useState<string>('');
    const [ville, setVille] = useState<string | null>('');

    const handleChange = (e: SelectChangeEvent) => {
        setType(e.target.value as string)
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            type: type,
            ville: ville
        }
        // alert(JSON.stringify(data));
        navigate("/rental")
    }

    return (
        <div className='bg-white px-4 md:px-12 rounded-md py-6 w-full'>
            <form onSubmit={handleSubmit} className={`flex flex-col gap-4`}>
                <div className='flex flex-col gap-3'>
                    <div className='w-full'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" style={{ backgroundColor: "white" }}>Avec ou sans chauffeur?</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Age"
                                onChange={handleChange}
                                required
                            >
                                {
                                    locationType && locationType.map((item) => {
                                        return <MenuItem value={item.label} key={item.label} style={{ fontSize: 14 }}>{item.label}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className='w-full'>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            value={ville ? ville : ''}
                            onChange={(event: any, newValue: string | null) => {
                                setVille(newValue);
                            }}
                            options={villes.map((option) => option.title)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Ville"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search'
                                    }}
                                />
                            )}

                        />
                    </div>
                </div>

                <div className='flex flex-col gap-2'>
                    <div>
                        {
                            type ? (
                                <Button variant='contained' type='submit' className='py-3 bg-secondary hover:bg-secondary' sx={{ textTransform: "capitalize", borderRadius: 2 }} fullWidth>Louer une voiture 🚗 🚙 🚐 🚚 🚌</Button>
                            ) :
                                (
                                    <Button variant='contained' className='py-3' sx={{ textTransform: "capitalize", borderRadius: 2 }} disabled endIcon={<DirectionsRunIcon />} fullWidth>Louer une voiture 🚗 🚙 🚐 🚚 🚌</Button>
                                )
                        }
                    </div>
                        🚗 🚙 🚐 🚚 🚌
                </div>

            </form>
        </div>
    )
}

export default SearchForm