// @ts-ignore 
// @ts-nocheck
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { appConfig } from '../../../contants/configurations';
import axiosInstance from "../../../utils/axiosInstance";
import Dashboardlayout from './../../../layouts/Dashboardlayout';

let emptyUser = {
    id: '',
    nom: '',
    prenom: '',
    date_debut: '',
    date_fin: '',
    voiture: null,
}

function ReservationList() {
    const [selectedEmploye, setSelectedEmploye] = useState<[] | null>(null);
    // @ts-ignore
    const [rowClick, setRowClick] = useState<boolean>(true);
    const toast = useRef<Toast>(null)
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const { isLoading, error, data, refetch } = useQuery('fetchReservation', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/reservation`);
        return data.data;
    })

    if (error) {
        return <span>Error ...</span>
    }

    const redirectWhatsapp = (RowData: any) => {
        window.open(
            `https://wa.me/+${RowData.telephone}?text=Salut à vous ?`
          )
    }

    const confirmDelete = (id: string) => {
        Swal.fire({
            title: 'Voulez-vous vraiment le supprimer ?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`${appConfig.API_URL}/reservation/${id}`).then(response => {
                    Swal.fire(
                        'Supprimé!',
                        response.data.message,
                        'success'
                    )
                    refetch()
                });
            }
        })

    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };
        // @ts-ignore
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // Toolbar
    // --- Left side
    const startContent = (
        <React.Fragment>
            {/* <Button label="Nouveau" size='small' icon="pi pi-plus" onClick={openAddEmployeDialog} className="mr-2 bg-primarydeep h-10 border-none" /> */}
            <Button label="Supprimer" size='small' icon="pi pi-trash" className='h-10' severity="danger" disabled={!selectedEmploye || !selectedEmploye.length} />
        </React.Fragment>
    );

    const endContent = (
        <React.Fragment>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} className='text-sm' placeholder="Rechercher..." />
            </span>
        </React.Fragment>
    );

    const actionButtons = (RowData: any) => {
        return <div>
            <Button icon="pi pi-whatsapp" rounded outlined className="mr-2" onClick={() => redirectWhatsapp(RowData)} />
            <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(RowData._id)}
            />
        </div>
    }

    const displayCar = (rowData: any) => {
        return <span>{rowData?.voiture[0]?.immatriculation}</span>;
    }

    const statusBodyTemplate = (rowData: any) => {
        return rowData.statut === 0 ? <Tag value="En cours" severity='danger'></Tag> : <Tag value="Activé" severity='info'></Tag>
    };

    const formatDebutDate = (rowData: any) => {
        let date = new Date(rowData.date_debut);
        return <span>{date.toLocaleDateString('en-GB')}</span>;
    }

    const formatEndDate = (rowData: any) => {
        let date = new Date(rowData.date_fin);
        return <span>{date.toLocaleDateString('en-GB')}</span>;
    }

    return (
        <Dashboardlayout title="Liste des Reservations">
            <div className='h-full'>
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="shadow bg-white rounded-lg p-8 flex flex-col gap-3">
                    <Toolbar start={startContent} end={endContent} />
                    {
                        isLoading ? <ProgressSpinner /> :
                            <DataTable value={data} selectionMode={rowClick ? undefined : 'multiple'} size='small'
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                paginator
                                rows={5}
                                dataKey="_id"
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                filters={filters} filterDisplay="menu"
                                globalFilterFields={['nom', 'prenom', 'telephone', 'voiture']}
                                emptyMessage="Aucune réservation disponibles."
                                tableStyle={{ minWidth: '40rem' }}>
                                <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
                                <Column field="nom" sortable header="Nom"></Column>
                                <Column field="prenom" sortable header="Prénom"></Column>
                                <Column field="telephone" sortable header="Telephone"></Column>
                                <Column field="itinairaire" sortable header="Itinairaire"></Column>
                                <Column field="date_debut" body={formatDebutDate} sortable header="Date de Début"></Column>
                                <Column field="date_fin" body={formatEndDate} sortable header="Date de Fin"></Column>
                                <Column field="voiture" sortable body={displayCar} header="Immatric."></Column>
                                <Column header="Statut" body={statusBodyTemplate}></Column>
                                <Column header="Action" body={actionButtons}></Column>
                            </DataTable>
                    }
                </div>
            </div>
        </Dashboardlayout>
    )
}

export default ReservationList