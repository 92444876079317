import { Button } from "@mui/material";
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { appConfig } from '../../contants/configurations';
import IMAGES from '../../contants/images';
import style from "./style.module.css";

function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password,
        }
        await axios.post(`${appConfig.API_URL}/auth/signin`, data).then((response) => {
            window.localStorage.setItem("accessToken", response.data.accessToken)
            window.localStorage.setItem("name", response.data.name)
            window.localStorage.setItem("email", response.data.email)
            if (response.data.roles[0] === "ROLE_ADMIN") {
                navigate("/dashboard");
                // alert(JSON.stringify(response.data))
            }
        }).catch((error) => {
            Swal.fire(
                'Identifiant Incorrect',
                'Veuillez vérifier vos identifiants',
                'error'
            )
        })
    }

    useEffect(()=>{
        const loggedInUser = localStorage.getItem('accessToken');
        if (loggedInUser) {
            navigate('/dashboard')
        }
    },[])

    return (
        <div className={`${style.background} bg-secondary h-screen px-4 flex justify-center items-center w-full`}>
            <div className='w-[600px] min-h-[500px] bg-white shadow-md rounded-xl overflow-hidden flex'>
                <div className='px-8 w-full md:w-full flex flex-col justify-center'>
                    <div className='flex flex-col gap-6'>
                        <div className='flex flex-col items-center justify-center'>
                            <div>
                                <img src={IMAGES.LOGO2} alt="Logo" className='w-[120px]' />
                            </div>
                            <div className='font-semibold uppercase text-[16px] text-primary'>Sénégal Transport Solution</div>
                        </div>
                        <form className='w-full flex flex-col gap-4' onSubmit={handleSubmit}>
                            <div className='flex flex-col'>
                                <label htmlFor="email" className='font-medium mb-2'>Email</label>
                                <InputText
                                    placeholder='Entrer votre email'
                                    type='email'
                                    required
                                    autoCapitalize='false'
                                    autoComplete='none'
                                    autoFocus={false}
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                    className='w-full'
                                />
                            </div>
                            <div className='flex flex-col'>
                                <label htmlFor="email" className='font-medium mb-2'>Mot de Passe</label>
                                <InputText
                                    placeholder='Entrer votre mot de passe'
                                    type='password'
                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                    className='w-full'
                                /></div>
                            <div className='flex justify-end'>
                                <Link to="/forgot-password" className='text-primary'>Mot de Passe oublié ?</Link>
                            </div>
                            <Button variant='contained' type='submit' className='py-3 bg-secondary hover:bg-[#207708]' sx={{ textTransform: "capitalize", borderRadius: 2 }}>Se connecter</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login




{/* <div className='bg-primary h-screen px-4 flex justify-center items-center w-full'>
            <div className='w-[950px] min-h-[500px] bg-white shadow-md rounded-xl overflow-hidden flex'>
                <div className='w-full md:w-1/2 hidden md:inline-flex bg-slate p-12 items-center justify-center'>
                    <div>
                        <img src={IMAGES.CAR2} alt="" className='w-full object-contain' />
                    </div>
                </div>
                <div className='px-8 w-full md:w-1/2 flex flex-col justify-center'>
                    <div className='flex flex-col gap-6'>
                        <div className='flex flex-col items-center justify-center'>
                            <div>
                                <FcAutomotive size={60} />
                            </div>
                            <div className='font-semibold uppercase text-[16px] text-primary'>Sénégal Transport Solution</div>
                        </div>
                        <form className='w-full flex flex-col gap-4' onSubmit={handleSubmit}>
                            <div>
                                <TextField type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='w-full' required placeholder='Entrez votre email' />
                            </div>
                            <div>
                                <TextField type='password' value={password} onChange={(e) => setPassword(e.target.value)} className='w-full' required placeholder='Entrez votre mot de passe' />
                            </div>
                            <div className='flex justify-end'>
                                <Link to="/forgot-password" className='text-primary'>Mot de Passe oublié ?</Link>
                            </div>
                            <Button variant='contained' type='submit' className='py-3 bg-[#0b1c70] hover:bg-[#112587]' sx={{ textTransform: "capitalize", borderRadius: 2 }}>Se connecter</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div> */}