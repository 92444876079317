import { ReactNode } from "react"

interface Props {
    title: string
    icon: ReactNode
    count: number
}

function StatisticCard({ title, icon, count }: Props) {
    return (
        <div className='flex items-center gap-3 bg-white drop-shadow-sm w-full group rounded-md px-6 py-8 cursor-pointer'>
            <div className='animation duration-300 rounded-full bg-slate-100 p-4 group group-hover:bg-slate-200'>
                {icon}
            </div>
            <div className='flex flex-col'>
                <span>{title}</span>
                <span className=' font-bold text-2xl'>{count}</span>
            </div>
        </div>
    )
}

export default StatisticCard