// @ts-nocheck
import { Box, Container, Divider, FormControl, InputLabel, MenuItem, Skeleton, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import Grid from '@mui/material/Grid/Grid'
import Select, { SelectChangeEvent } from '@mui/material/Select/Select'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/system'
import axios from 'axios'
import { DataView } from 'primereact/dataview'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import VehiclesCard from '../../components/Cards/VehiclesCard'
import { appConfig } from '../../contants/configurations'
import Footer from '../../partials/Footer/Footer'
import Navbar from '../../partials/Navbar/Navbar'
import Sidebar from '../../partials/Sidebar/Sidebar'
import { VehicleSearchAttributes } from './RentalTypes'

const useStyles = makeStyles((theme: Theme) => ({
    layout: {
        display: "flex",
        marginTop: 120,
        height: "100%",
        gap: 22,
        marginBottom: 70
    },
    sidebar: {
        width: "25%",
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    content: {
        width: "75%",
        display: "flex",
        flexDirection: "column",
        gap: 15,
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    vehicles: {
    },
    vehicle_card: {
        borderRadius: 5,
        padding: 10,
        backgroundColor: "#ffffff",
        boxShadow: "rgba(145, 158, 171, 0.2) 0px 3px 3px -2px, rgba(145, 158, 171, 0.14) 0px 3px 4px 0px, rgba(145, 158, 171, 0.12) 0px 1px 8px 0px",
        display: "flex",
        flexDirection: "row",
        marginBottom: 15,
        gap: 20,
        "& .image": {
            width: 300,
            height: 220,
            backgroundColor: "lightblue",
            borderRadius: 5,
            // objectFit: "contain",
            overFlow: "hidden",
            padding: 30,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "100%"
            }
        },
        "& .content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "70%",
            padding: 5,
            "& .top": {
                display: "flex",
                flexDirection: "column",
                "& .label": {
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 4,
                    "& div": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }
                },
                "& .characteristics": {
                    marginTop: 15,
                    display: "grid",
                    gridTemplateColumns: "repeat(3,1fr)",
                    gap: 15
                },
                "& .characteristics div": {
                    display: "flex"
                }

            },
            "& .bottom": {
                display: "flex",
                flexDirectio: "row",
                justifyContent: "space-between",
                "& h3": {
                    fontSize: 13,
                    "& span": {
                        fontSize: 7,
                        fontWeight: 500
                    }
                },
                "& div": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
        }
    },
}));

const locationType = [
    {
        label: "Location de voiture sans chauffeur"
    },
    {
        label: "Location de voiture avec chauffeur"
    },
    {
        label: "Trajets simple vers/depuis l'aéroport AIBD"
    },
]

const villes = [
    { title: 'Dakar' },
    { title: 'Mbour-Saly' },
    { title: 'Cap Skirring' },
    { title: 'Thiès' },
    { title: 'Touba' },
    { title: 'Saint Louis' },
    { title: 'Diourbel' },
    { title: 'Kaolack' },
    { title: 'Ziguinchor' },
    { title: 'Kédougou' },
    { title: 'Tambacounda' },
    { title: 'Matam' },
    { title: 'Louga' },
    { title: 'Kolda' },
    { title: 'Séhdiou' },
    { title: 'Kaffrine' },
];

function Rental() {
    const classes = useStyles();

    const [type, setType] = useState<string>(locationType[0].label);
    const [ville, setVille] = useState<string | null>('');

    const [sortKey, setSortKey] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('');
    const [vehicleData, setVehicleData] = useState<any>([])

    const [filterQuery, setFilterQuery] = useState<VehicleSearchAttributes>(() => {
        const initFilter: VehicleSearchAttributes = {
            type: [],
            options: [],
            vitesse: [],
            moteur: [],
            prix: 0,
        }
        return initFilter;
    })

    const { isLoading, error, data, isSuccess } = useQuery('vehicleData', async () => {
        const { data } = await axios(`${appConfig.API_URL}/voiture`);
        setVehicleData(data?.data || [])
        return data;
    })

    if (error) {
        return <span>Error ...</span>
    }


    //function to filterVehicles
    const filterVehicles = () => {
        let arr = data?.data
        let shouldFilter = (
            () => {
                let res = false;
                res = Object.values(filterQuery).some((item) => item.length > 0)
                return res || filterQuery?.prix > 0
            }
        )()

        if (shouldFilter) {
            const keys = Object.keys(filterQuery)

            keys.forEach((key, index) => {
                let temp = data?.data
                switch (key) {
                    case 'type':
                        if(filterQuery?.type?.length > 0)
                            arr = filterItem(key, filterQuery?.type, data?.data)
                        break;

                    case 'options':
                        if (filterQuery?.options?.length > 0) {
                            arr = filterItem(key, filterQuery?.options, temp)
                        }
                        break;

                    case 'vitesse':
                        if (filterQuery?.vitesse?.length > 0) {
                            arr = filterItem(key, filterQuery?.vitesse, temp)
                        }
                        break;

                    case 'moteur':
                        if (filterQuery?.moteur?.length > 0) {
                            arr = filterItem(key, filterQuery?.moteur, temp)
                        }
                        break;

                    case 'prix':
                        if(filterQuery?.prix > 0){
                            arr = filterItem(key,filterQuery?.prix,temp)
                        }
                        break;

                    default:
                        break;
                }
            })

        }

        setVehicleData(arr)
        if (arr && arr.length > 1)
            return sortData(arr);

        return arr;
    }

    const filterItem = (key, filterData, actualArray) => {
        let result = []

        if (key === 'type') {
            filterData.forEach((item) => {
                const data = actualArray.filter((subitem) => subitem?.marque[0]?.name?.toLowerCase() === item.toLowerCase())
                if (data?.length > 0) {
                    result.push(data[0])
                }
            })
        }
    
        if (key === 'options') {
            filterData.forEach((item) => {
                actualArray.forEach(sampleDataObj => {
                    let counter = 0;
                    Object.keys(sampleDataObj?.options).forEach((dataOption) => {
                        if (dataOption.includes(item) && sampleDataObj?.options[dataOption]) {
                            ++counter
                        }
                    })
                    if (counter > 0)
                        result.push(sampleDataObj)
                })
            })
        }

        if (key === 'vitesse') {
            filterData.forEach((item) => {
                actualArray.forEach(sampleDataObj => {
                    if (sampleDataObj?.transmission.toLowerCase().includes(item.toLowerCase())) {
                        result.push(sampleDataObj)
                    }
                })
            })
        }

        if (key === 'moteur') {
            filterData.forEach((item) => {
                actualArray.forEach(sampleDataObj => {
                    if (sampleDataObj?.moteur.toLowerCase() === item.toLowerCase()) {
                        result.push(sampleDataObj)
                    }
                })
            })
        }

        if (key === 'prix'){
            const price = filterData;

            console.log("Price is" + price);
            
            actualArray.forEach(sampleDataObj => {
                if (sampleDataObj?.prix > 0 && sampleDataObj?.prix<= price | sampleDataObj?.prix>= price) {
                // if (sampleDataObj?.prix > 0 && sampleDataObj?.prix<= price * 650) {
                    result.push(sampleDataObj)
                }
            })

            console.log("Result: ",result)
        }

        return result;
    }

    //function to sort data
    const sortData = (arr) => {
        const set = new Set([...arr])
        const temp = []
        for (const obj of set) {
            temp.push(obj)
        }

        return temp;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const filterQueryMemo = useMemo(() => filterVehicles(), [filterQuery, data])


    const handleChange = (e: SelectChangeEvent) => {
        setType(e.target.value as string)
    }

    interface SortOption {
        label: string;
        value: string;
    }

    const sortOptions: SortOption[] = [
        { label: 'Les moins chères', value: 'prix' },
        { label: 'Les plus chères', value: '!prix' }
    ];

    const onSortChange = (event: DropdownChangeEvent) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    const header = () => {
        return <Dropdown options={sortOptions} value={sortKey} optionLabel="label" placeholder="Meilleurs Résultats" onChange={onSortChange} className="w-ful sm:w-14rem text-sm flex self-end" />;
    };

    return (
        <div>
            <Container maxWidth="lg">
                <Navbar />
                <Box className={classes.layout}>
                    <Box className={classes.sidebar}>
                        <Sidebar setFilterQuery={setFilterQuery} filterQuery={filterQuery} />
                    </Box>

                    {/* The content changes dynamically according to value in sidebar */}
                    <Box className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" shrink={true} style={{ backgroundColor: "white" }}>Type de prestation *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Age"
                                        onChange={handleChange}
                                        required
                                    >
                                        {
                                            locationType && locationType.map((item) => {
                                                return <MenuItem value={item.label} key={item.label}>{item.label}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    disableClearable
                                    value={ville ? ville : ''}
                                    onChange={(event: any, newValue: string | null) => {
                                        setVille(newValue);
                                    }}
                                    options={villes.map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Ville"

                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search'
                                            }}
                                        />
                                    )}

                                />
                            </Grid>

                        </Grid>

                        {/* Vehicles */}
                        <div className='flex flex-col'>
                            {
                                isLoading && !error ? [1, 2, 3, 4, 5].map((item): any => {
                                    return <Box className={classes.vehicle_card} key={item}>
                                        <Skeleton animation="wave" variant='rectangular' className='image bg-gray-200 overflow-hidden object-scale-down h-full w-full md:w-[300px] md:h-[200px] rounded-md' />
                                        <Link to="#" className='content'>
                                            <div className='top gap-2'>
                                                <div className="label">
                                                    <Skeleton variant='rectangular' animation='pulse' height={25} className='w-full rounded-md' />
                                                </div>
                                                <Divider />
                                                <div className="options grid grid-cols-2 md:grid-cols-3 gap-2">
                                                    {
                                                        [1, 2, 3, 4, 5, 6].map(item => {
                                                            return <Skeleton variant='rectangular' className='h-[20px] w-full rounded-sm' animation='pulse' />
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="bottom mt-2 flex gap-16">
                                                <Skeleton variant='rectangular' animation='pulse' className='w-full rounded-sm' />
                                                <Skeleton variant='rectangular' animation='pulse' className='w-full rounded-sm' />
                                            </div>
                                        </Link>
                                    </Box>
                                }) : data && isSuccess && <DataView dataKey='_id' value={filterQueryMemo} header={header()} sortField={sortField} sortOrder={sortOrder} itemTemplate={VehiclesCard} paginator={false} rows={9} />
                            }

                        </div>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </div >
    )
}

export default Rental
