// @ts-nocheck
import { Skeleton } from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import React from "react";
import { useQuery } from "react-query";
import CarCard from "../../../components/Cards/CarCard";
import { appConfig } from "../../../contants/configurations";

const Landingcar: React.FC = () => {
  const voiture = useQuery("fetchCars", async () => {
    const { data } = await axios.get(`${appConfig.API_URL}/voiture`);
    return data.data;
  });

  return (
    <div className="py-20 px-4 md:px-[120px] flex flex-col gap-16 bg-white">
      <div className="flex flex-col gap-10">
        <div>
          <h2 className="text-primary font-semibold text-xl md:text-3xl lg:text-4xl inline-block">
            Nos meilleures voitures en locations
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-10">
          {voiture.isLoading || voiture.error
            ? [1, 2, 3, 4, 5, 6].map((_, key) => {
                return (
                  <motion.div
                    key={key}
                    className="bg-white inline-block shadow-2xl rounded-md overflow-hidden cursor-pointer"
                  >
                    <div className="overflow-hidden w-full h-56">
                      <Skeleton
                        variant="rectangular"
                        animation="pulse"
                        height={224}
                        className="animation w-full h-full duration-300 hover:scale-110"
                      />
                    </div>
                    <div className="p-4 flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <Skeleton
                          variant="rectangular"
                          animation="pulse"
                          height={20}
                          className="w-1/2 rounded-md"
                        />
                        <div className="text-sm text-primary">
                          <Skeleton
                            variant="rectangular"
                            animation="pulse"
                            height={20}
                            className="w-full rounded-md"
                          />
                        </div>
                        <Skeleton
                          variant="rectangular"
                          animation="pulse"
                          height={20}
                          className="w-full rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div>
                          <div className="flex justify-between text-base">
                            <Skeleton
                              variant="rectangular"
                              animation="pulse"
                              height={20}
                              className="w-1/2 rounded-md"
                            />
                          </div>
                          <div className="flex justify-between text-base gap-2 mt-2">
                            <Skeleton
                              variant="rectangular"
                              animation="pulse"
                              height={20}
                              className="w-full rounded-md"
                            />
                            <Skeleton
                              variant="rectangular"
                              animation="pulse"
                              height={20}
                              className="w-full rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <hr className="bg-gray-400 h-[1.6px] mt-2 outline-none border-none" />
                        </div>
                      </div>
                    </div>
                  </motion.div>
                );
              })
            : voiture?.data &&
              voiture?.data?.slice(0, 6).map((item: any) => {
                return <CarCard key={item._id} item={item} />;
              })}
        </div>
        <div className="flex justify-end">
          {voiture.isSuccess && (
            <div>
              <button
                onClick={() => window.open("/rental", "_self")}
                className="p-4 bg-white hover:bg-primary hover:text-white rounded-md text-primary outline-none border-secondary border-[1px] animation duration-200 cursor-pointer"
              >
                Voir toutes les locations de voiture
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Landingcar;
