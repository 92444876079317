// @ts-ignore 
// @ts-nocheck
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Bs0Square, BsLock, BsPerson } from 'react-icons/bs';
import {
    FaAccusoft,
    FaBriefcase,
    FaCar,
    FaHome,
    FaUsers
} from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { MdMenu } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import IMAGES from "../../contants/images";

const DashboardSidebar = () => {
    let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
    const [open, setOpen] = useState(isTabletMid ? false : true);
    const sidebarRef = useRef<HTMLInputElement>(null);
    const { pathname } = useLocation();
    const path = document.location.pathname;

    useEffect(() => {
        if (isTabletMid) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [isTabletMid]);

    useEffect(() => {
        isTabletMid && setOpen(false);
    }, [pathname]);

    useEffect(()=>{
        const loggedInUser = localStorage.getItem('accessToken');
        if (!loggedInUser) {
            navigate('/admin')
        }
    },[])

    const navigate = useNavigate();

    const Nav_animation = isTabletMid
        ? {
            open: {
                x: 0,
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                x: -250,
                width: 0,
                transition: {
                    damping: 40,
                    delay: 0.15,
                },
            },
        }
        : {
            open: {
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                width: "4rem",
                transition: {
                    damping: 40,
                },
            },
        };

    const links = [
        { id: 0, label: "Accueil", path: "/dashboard", icon: <FaHome size={23} className='min-w-max' /> },
        { id: 1, label: "Comptes", path: "/users", icon: <BsPerson size={23} className='min-w-max' /> },
        { id: 2, label: "Marques ", path: "/marque", icon: <Bs0Square size={23} className='min-w-max' /> },
        { id: 3, label: "Modèle ", path: "/model", icon: <FaAccusoft size={23} className='min-w-max' /> },
        { id: 4, label: "Véhicules ", path: "/cars", icon: <FaCar size={23} className='min-w-max' /> },
        { id: 5, label: "Reservations ", path: "/reservation", icon: <FaUsers size={23} className='min-w-max' /> },
        { id: 6, label: "Contract ", path: "/contrat", icon: <FaBriefcase size={23} className='min-w-max' /> },
        // { id: 7, label: "Paramètres ", path: "/admin", icon: <FaCogs size={23} className='min-w-max' />, gap: true }
    ];

    const logOut = () => {
        window.localStorage.removeItem("accessToken")
        window.localStorage.removeItem("email")
        navigate("/admin")
    }

    return (
        <div>
            <div
                onClick={() => setOpen(false)}
                className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${open ? "block" : "hidden"
                    } `}
            ></div>
            <motion.div
                ref={sidebarRef}
                variants={Nav_animation}
                initial={{ x: isTabletMid ? -250 : 0 }}
                animate={open ? "open" : "closed"}
                className=" bg-primary text-gray shadow-xl z-[999] max-w-[16rem] w-[16rem] overflow-hidden md:relative fixed h-screen"
            >
                <div className="flex gap-2.5 font-medium border-b py-3 border-slate-300 mx-3 mt-4">
                    <div className="flex items-center gap-2 ml-3">
                        <img src={IMAGES.LOGO2} alt="Logo" className="rounded-full w-10 h-10" />
                        <span className="text-white text-4xl tracking-widest font-semibold">STS</span>
                    </div>
                    {/* <div>
                        <Avatar alt="SM" src="/static/images/avatar/2.jpg" />
                    </div>
                    <div className="flex flex-col text-slate text-center text-sm">
                        <span>
                            {window.localStorage.getItem("name")}
                        </span>
                        <span>
                            {window.localStorage.getItem("email")}
                        </span>
                    </div> */}
                </div>

                <div className="flex flex-col h-full text-white">
                    <ul className="whitespace-pre px-2.5 text-[0.9rem] py-1 flex flex-col gap-1 font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 md:h[68%] h[70%]">
                        <div className="flex flex-col gap-0">
                            {
                                links && links.map(item => {
                                    return <NavLink to={item.path} key={item.id} className={`link animation no-underline duration-200 mb-2 flex items-center gap-3 ml-3 py-3 px-4 rounded-lg hover:bg-white hover:text-primary ${item.path === path ? 'bg-white text-primary' : 'text-white'}`}>
                                        {item.icon}
                                        {item.label}
                                    </NavLink>
                                })
                            }
                        </div>
                    </ul>
                    {open && (
                        <div onClick={() => logOut()} className="flex-1 text-sm z-50 max-h-48 my-auto whitespace-pre w-full font-medium">
                            <div className="link mb-4 flex items-center cursor-pointer gap-3 ml-4 py-3 px-4 mx-3 rounded-lg bg-green-500 hover:bg-white/90 text-primary">
                                <BsLock size={23} className="min-w-max" />
                                Déconnexion
                            </div>
                        </div>
                    )}
                </div>
                <motion.div
                    onClick={() => {
                        setOpen(!open);
                    }}
                    animate={
                        open
                            ? {
                                x: 0,
                                y: 0,
                                rotate: 0,
                            }
                            : {
                                x: -10,
                                y: -200,
                                rotate: 180,
                            }
                    }
                    transition={{ duration: 0 }}
                    className="absolute w-fit h-fit md:block z-50 hidden right-2 bottom-3 cursor-pointer"
                >
                    <IoIosArrowBack size={25} className="text-white" />
                </motion.div>
            </motion.div>
            <div className="m-3 md:hidden " onClick={() => setOpen(true)}>
                <MdMenu size={25} className="text-white" />
            </div>
        </div>
    );
};

export default DashboardSidebar;

































// import { useState } from 'react'
// import { Link } from 'react-router-dom';
// import SidebarData from './SidebarData';
// import { BsChevronLeft } from 'react-icons/bs';

// function DashboardSidebar() {
//     const [open, setOpen] = useState<boolean>(true);

//     const pathname = window.location.pathname;

//     return (
//         <div
//             className={`${open ? "w-20 md:w-72" : "w-20"
//                 } bg-primary inset-0 md:relative fixed overflow-hidden h-screen p-5 pt-8 duration-300`}
//         >
//             <div onClick={() => setOpen(!open)} className='absolute cursor-pointer -right-3 top-9 w-7 h-7 flex justify-center items-center bg-white border-primarydeep border-2 shadow rounded-full'>
//                 <BsChevronLeft size={18}
//                     className={`text-primarydeep   ${!open && "rotate-180"}`}
//                 />
//             </div>
//             <div className="flex gap-x-4 items-center">
//                 <img
//                     src="https://cdn-icons-png.flaticon.com/512/219/219969.png"
//                     className={`cursor-pointer duration-500 w-9 ${open && "rotate-[360deg]"}`}
//                 />
//                 <h1
//                     className={`hidden md:block text-primary origin-left font-medium text-xl duration-200 ${!open && "scale-0"}`}
//                 >
//                     Admin.
//                 </h1>
//             </div>
//             <ul className="pt-">
//                 {SidebarData.map((item, index) => (
//                     <Link to={item.path}
//                         key={index}
//                         className={`flex rounded-md p-2 cursor-pointer hover:bg-slate text-primary text-sm items-center gap-x-4
//               ${item.gap ? "mt-9" : "mt-2"} ${pathname === item.path && "bg-slate"
//                             } `}
//                     >
//                         {item.icon}
//                         <span className={`${!open ? "hidden" : 'hidden md:block'} origin-left duration-200`}>
//                             {item.label}
//                         </span>
//                     </Link>
//                 ))}
//             </ul>
//         </div>
//     )
// }

// export default DashboardSidebar