const appConfig = {
    API_URL: process.env.REACT_APP_BACKEND,
    IMAGE_URL: process.env.REACT_APP_IMAGES
};

const authConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clienId: process.env.REACT_APP_CLIENT_ID,
    API_URL: process.env.REACT_APP_BACKEND
}

export { authConfig, appConfig };