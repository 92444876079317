import { Document, Image, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { appConfig } from '../../../contants/configurations';
import axiosInstance from '../../../utils/axiosInstance';

const styles = StyleSheet.create({
    page: {
        marginTop: 1,
        fontSize: 11,
        paddingHorizontal: 20,
    },
    layout: {
        marginTop: 30,
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    flex_row: {
        display: "flex",
        flexDirection: 'row',
    },
    flex_col: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: "baseline"
    },
    image: {
        width: 50,
        height: 40,
        alignSelf: "center",
        marginBottom: 5
    },
    green_text: {
        fontSize: 10,
        textAlign: "center",
        color: "green",
        marginBottom: 5
    },
    text_seperate: {
        marginBottom: 10,
        marginTop: 10
    },
    green_btn: {
        backgroundColor: "green",
        borderRadius: "100%",
        fontSize: 14,
        paddingVertical: 6,
        paddingHorizontal: 10,
        color: "white"
    },
    black_btn: {
        backgroundColor: "black",
        borderRadius: "100%",
        fontSize: 10,
        paddingVertical: 6,
        paddingHorizontal: 10,
        color: "white",
        marginBottom: 7
    }
});

function Contract() {

    const params = useParams();

    const { isLoading,data } = useQuery('vehicleDetails', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/contract/${params.id}`)
        return data.data;
    })

    if (isLoading) {
        return <div className='flex h-screen w-full items-center justify-center'>
            <ProgressSpinner />
        </div>
    }

    return (
        <PDFViewer className='w-full h-screen bg-red600'>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.layout}>
                        <View>
                            <Image
                                src='https://autowou-legenie1.vercel.app/static/media/logo.0262b6721a70f31978d0.png'
                                style={styles.image} />
                        </View>
                        <View style={[styles.flex_col, { alignContent: "center", alignItems: "center" }]}>
                            <Text style={[styles.green_text]}> - Vente de billets d'avions - reservation hotels - assurance voyage - location de voitures - tourisme</Text>
                            <Text style={[styles.green_text]}> Adresse: Cité Damel / Tel : 77 446 23 70 / 77537 28 93</Text>
                            <View style={[styles.green_btn]}>
                                <Text style={{ fontWeight: "extrabold" }}>CONTRAT DE LOCATION</Text>
                            </View>
                            <Text style={{ fontSize: 11, fontWeight: "extrabold", marginTop: 4 }}>N° CL/STS/......./...........</Text>
                        </View>
                        <View style={[styles.flex_col]}>
                            <View style={[styles.black_btn]}>
                                <Text>ENTRE LES SOUSSIGNES</Text>
                            </View>
                            <View style={[styles.flex_col]}>
                                <View style={[styles.flex_row, { fontSize: 11 }]}>
                                    <Text>NOMS ..........................{data.nom}.............................................. </Text>
                                    <Text>PRENOMS ........................{data.prenom}....................................................... </Text>
                                </View>
                                <View style={[styles.flex_row]}>
                                    <Text>COORDONNES ............{data.tel}............... </Text>
                                    <Text>DATE ET LIEU DE NAISSANCE: ........{data.datenaiss} à {data.lieunaiss}................ </Text>
                                </View>
                                <View style={[styles.flex_row]}>
                                    <Text>Adresse ....................{data.adresse}.................... </Text>
                                    <Text>NUMERO PERMIS ................{data.numpermis}........... en sa qualité</Text>
                                </View>
                                <Text> `CLIENT` et </Text>
                                <View>
                                    <Text>Sénégal Transport Solutions, sise à l'aéroport Léopold Sédar, RC N° SN-DKR2021-A-25558, Ninea N° 008752827, représentée par Monsieur Boubacar DIOP en sa qualité de Directeur `LOUEUR` </Text>
                                </View>

                                <Text style={[styles.text_seperate]}>CECI ETANT RAPPELE, IL A ETE CONVENU CE QUI SUIT</Text>
                            </View>
                            {/* Article 1 */}
                            <View style={[styles.black_btn]}>
                                <Text>ARTIVLE 1. OBJET DU CONTRAT</Text>
                            </View>
                            <View>
                                <Text>Le présent contart a pour objet la location d'un Véhicule: </Text>
                                <View style={[styles.flex_row]}>
                                    <Text>De type et de marque:.................................................. </Text>
                                    <Text>Numéro Immatriculation ...............{data.voiture[0] && data.voiture[0].immatriculation}.........................................</Text>
                                </View>
                                <View style={[{ marginTop: 15, marginBottom: 7 }]}>
                                    <Text>Est expressément exclu du présent contrat </Text>
                                    <Text>- Le transport de marchandises ou de voyageurs à titre onéreux </Text>
                                </View>
                                <View style={[{ marginTop: 15, marginBottom: 7 }]}>
                                    <Text>1.1 Description de la voiture objet de la location</Text>
                                    <View style={[styles.flex_row]}>
                                        <Text>- Auto ..................{data.auto}............................................. </Text>
                                        <Text>nbr cv .........................{data.nbr_cv}.........................................</Text>
                                    </View>
                                    <View style={[styles.flex_col]}>
                                        <Text>- Nbres de places ................................{data.voiture[0] && data.voiture[0].nombre_place}............................................................................................................... </Text>
                                        <Text>- Date de 1e Mise en Circulation ................................................................................................................................. </Text>
                                        <Text>- Destinations .........................{data.destination}...................................................................................................................... </Text>
                                        <Text>- Date et Heure de départ ...............{data.depart}..................................................................................................... </Text>
                                        <Text>- Date et Heure de retour ...............{data.retour}...................................................................................................... </Text>
                                        <Text>- Caution Versée ................{data.caution}.......................................................................................................................... </Text>
                                    </View>
                                    <Text style={{ marginTop: 10 }}>Cette Location est est non transmissible à un tiers</Text>
                                </View>
                            </View>

                            <View style={[styles.black_btn]}>
                                <Text>ARTICLE 2. PRIX DE LOCATION ET MODALITES DE PAIEMENT</Text>
                            </View>

                            <View style={styles.flex_col}>
                                <Text>Le prix de location du Bien convenu est de :</Text>
                                <View style={[styles.flex_row, { alignItems: "center", gap: 4, color: "green" }]}>
                                    <View>
                                        <Text>Prix/jour ..............{data.prix_jour}.................</Text>
                                        <Text>Prix/mois ...............{data.prix_mois}...............</Text>
                                    </View>
                                    <Text>
                                        PRIX TOTAL ...........................................
                                    </Text>
                                </View>
                                <Text style={{ marginBottom: 15, fontWeight: "extrabold" }}>Préciser le montant versé ce jour en espèces directement au LOUEUR</Text>
                                <View>
                                    <Text>.....................................................................................................................................................................................................................</Text>
                                    <Text>Il a été convenu entre les parties le règlement de cette opération virement dans le comte ouvert </Text>
                                    <Text>.....................................................................................................................................................................................................................</Text>
                                    <Text>Il est rappelé que le prix de la location est payé d'avance et en intégralité. </Text>
                                    <View style={[styles.flex_row]}>
                                        <Text>Dakar le ..................{data.date}.................... </Text>
                                        {/* <Text>Heure...........................................................</Text> */}
                                    </View>
                                    <Text>fait en Deux Examplaires</Text>
                                </View>
                            </View>

                            <View style={[styles.flex_row, { justifyContent: "space-between", width: "100%", marginTop: 15 }]}>
                                <Text style={{ textDecoration: "underline" }}>LE CLIENT </Text>
                                <Text style={{ textDecoration: "underline" }}>LE LOUEUR </Text>
                            </View>

                        </View>
                    </View>
                </Page >
            </Document >
        </PDFViewer >
    )
}

export default Contract