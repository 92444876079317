// @ts-ignore 
// @ts-nocheck
import { Icon } from '@iconify/react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { Link } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { valueLabelFormatUL } from '../../utils/PriceConversion';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import IMAGES from '../../contants/images';

const useStyles = makeStyles((theme: Theme) => ({
    vehicle_card: {
        borderRadius: 5,
        padding: 10,
        backgroundColor: "#ffffff",
        boxShadow: "rgba(145, 158, 171, 0.2) 0px 3px 3px -2px, rgba(145, 158, 171, 0.14) 0px 3px 4px 0px, rgba(145, 158, 171, 0.12) 0px 1px 8px 0px",
        display: "flex",
        flexDirection: "row",
        marginBottom: 15,
        color: "green",
        gap: 20,
        "& .content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "70%",
            padding: 5,
            "& .top": {
                display: "flex",
                flexDirection: "column",
                "& .label": {
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 4,
                    "& div": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }
                },
                "& .characteristics": {
                    marginTop: 15,
                    display: "grid",
                    gridTemplateColumns: "repeat(3,1fr)",
                    gap: 15
                },
                "& .characteristics div": {
                    display: "flex"
                }

            },
            "& .bottom": {
                display: "flex",
                flexDirectio: "row",
                justifyContent: "space-between",
                "& h3": {
                    fontSize: 13,
                    "& span": {
                        fontSize: 7,
                        fontWeight: 500
                    }
                },
                "& div": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
            display: "flex",
            flexDirection: "column"
        }
    },
}));

interface Props {
    _id: string
    marque: Array<Object>
    model: string
    location: string
    prix: number
    options?: any
    places: number
    portes: number | 4
    transmission?: string
    kilometrage?: number
    annee?: number
    carburateur?: string
    images?: Array<string>
}

function VehiclesCard({ _id, marque, model, location, prix, options, places, portes, transmission, kilometrage, annee, carburateur, images }: Props) {
    const classes = useStyles();

    return (
        <Box className={classes.vehicle_card} maxWidth>
            <Box className="image bg-gray-200 overflow-hidden h-[240px] w-full md:w-[300px] md:h-[240px] rounded-md px[30px] py-">
                <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{
                        clickable: true,
                    }}
                    spaceBetween={30}
                    navigation={true}
                    className="mySwiper h-full w-full"
                >
                    {
                        images ? images.map((item: any) => {
                            return <SwiperSlide key={item}>
                                <img src={item} alt={item} className='w-full h-full object-cover animation relative cursor-pointer duration-300 hover:scale-110' />
                            </SwiperSlide>
                        })
                            : <img src={IMAGES.CAR} alt="Chambres" className='w-full h-full animation relative cursor-pointer duration-300 hover:scale-110' />
                    }
                </Swiper>
            </Box>
            <Link to={`/rental/${_id}`}
                className="content text-gray-800">
                <div className="top">
                    <div className="label uppercase">
                        <h3>{marque[0]?.name + ' ' + model[0]?.name}</h3>
                    </div>
                    <div className="options">
                        <Divider sx={{ marginBottom: 1 }} />
                        <div className='text-sm'>
                            {
                                options.climatisation &&
                                <span>Climatisation - </span>
                            }
                            {
                                options.siege_bebe &&
                                <span>Siège bébé - </span>
                            }
                            {
                                options.siege_cuir &&
                                <span>Siège en Cuir - </span>
                            }
                            {
                                options.audio_bluetooth &&
                                <span>Audio bluetooth - </span>
                            }
                            {
                                options.gps &&
                                <span>GPS - </span>
                            }
                            {
                                options.camera &&
                                <span>Camera - </span>
                            }
                            {
                                options.regulateur &&
                                <span>Regulateur - </span>
                            }
                            {
                                options.recharge_usb &&
                                <span>Recharge Usb</span>
                            }
                        </div>
                        <Divider sx={{ marginTop: 1 }} />
                    </div>

                    <div className="characteristics">
                        {
                            places ? <div className='flex items-center gap-1'>
                                <Icon icon="mdi:car-seat" fontSize={15} />
                                <Typography sx={{ fontSize: 13 }}>{places} places</Typography>
                            </div> : null
                        }
                        {
                            portes ? <div className='flex items-center gap-1'>
                                <Icon icon="mdi:car-door" fontSize={15} />
                                <Typography sx={{ fontSize: 13 }}>{portes} portes</Typography>
                            </div> : null
                        }
                        {
                            transmission ? <div className='flex items-center gap-1'>
                                <Icon icon="mdi:cog-outline" fontSize={15} />
                                <Typography sx={{ fontSize: 13 }}>{transmission}</Typography>
                            </div> : null
                        }
                        {
                            kilometrage ? <div className='flex items-center gap-1'>
                                <Icon icon="mdi:car-speed-limiter" fontSize={15} />
                                <Typography sx={{ fontSize: 13 }}>{kilometrage} km</Typography>
                            </div> : null
                        }
                        {
                            annee ? <div className='flex items-center gap-1'>
                                <Icon icon="mdi:package-variant" fontSize={15} />
                                <Typography sx={{ fontSize: 13 }}>{new Date().getFullYear() - annee} ans</Typography>
                            </div> : null
                        }
                        {
                            carburateur ? <div className='flex items-center gap-1'>
                                <Icon icon="game-icons:gas-pump" fontSize={15} />
                                <Typography sx={{ fontSize: 13 }}>{carburateur}</Typography>
                            </div> : null
                        }
                    </div>
                </div >
                <div className="bottom mt-2">
                    <div className='flex items-center gap-1'>
                        <Icon icon="ic:baseline-star" fontSize={20} color='orange' />
                        <span className='text-[14px] font-semibold'>Nouveau</span>
                    </div>

                    <div className='flex items-center gap-1'>
                        <h3>{valueLabelFormatUL(prix)} <span>/jour</span></h3>
                        <InfoRoundedIcon fontSize='small' className='text-secondary' />
                    </div>
                </div>
            </Link >
        </Box >
    )
}

export default VehiclesCard