import { Button, TextField } from "@mui/material";
import React, { useState } from 'react';
import { FcAutomotive } from "react-icons/fc";
import IMAGES from '../../contants/images';

function NewPassword() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');

    const handleSubmit = (e: React.FormEvent<HTMLElement>): void => {
        e.preventDefault();
        const data = {
            password: password,
            password2: password2,
        }

        alert(JSON.stringify(data))
    }

    return (
        <div className='bg-primary h-screen px-4 flex justify-center items-center w-full'>
            <div className='w-[950px] min-h-[500px] bg-white shadow-md rounded-xl overflow-hidden flex'>
                <div className='w-full md:w-1/2 hidden md:inline-flex bg-slate p-12 items-center justify-center'>
                    <div>
                        <img src={IMAGES.FORGOT} alt="" className='w-full object-contain' />
                    </div>
                </div>
                <div className='px-8 w-full md:w-1/2 flex flex-col justify-center'>
                    <div className='flex flex-col gap-6'>
                        <div className='flex flex-col items-center justify-center'>
                            <div>
                                <FcAutomotive size={60} />
                                {/* <img src={IMAGES.CAR2} className='w-32' alt="" /> */}
                            </div>
                            <div className='font-semibold uppercase text-[16px] text-primary'>Sénégal Transport Solution</div>
                        </div>
                        <form className='w-full flex flex-col gap-4' onSubmit={handleSubmit}>
                            <div>
                                <TextField type='password' value={password} onChange={(e) => setPassword(e.target.value)} className='w-full' required placeholder='Nouveau mot de passe' />
                            </div>
                            <div>
                                <TextField type='password' value={password2} onChange={(e) => setPassword2(e.target.value)} className='w-full' required placeholder='Confirmer votre mot de passe' />
                            </div>
                            <Button variant='contained' type='submit' className='py-3 bg-[#0b1c70] hover:bg-[#112587]' sx={{ textTransform: "capitalize", borderRadius: 2 }}>Reinitialiser</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPassword