import IMAGES from '../../contants/images'
import { useNavigate } from 'react-router-dom'

function Notfound() {
    const navigate = useNavigate();

    return (
        <div className='w-full h-screen bg-secondary flex items-center justify-center'>
            <div>
                <div className='flex justify-center'>
                    <img src={IMAGES.FORGOT} alt="Notfound" />
                </div>
                <div className='flex flex-col items-center'>
                    <h2 className='text-white mb-3 text-3xl'>Page Introuvable</h2>
                    <button onClick={() => navigate(-1)} className='p-3 px-4 font-medium rounded-full outline-none cursor-pointer border-none animation duration-300 hover:translate-y-2'>Retour à la page precédente</button>
                </div>
            </div>
        </div>
    )
}

export default Notfound