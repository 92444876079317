
// @ts-nocheck
import { FcAssistant, FcPhoneAndroid, FcSynchronize } from "react-icons/fc"
import style from "./style.module.css"

function Features() {
    const lists = [
        {
            id: 0,
            icon: <FcSynchronize size={75} />,
            label: "Mise à jours",
            description: "Toutes les semaines"
        },
        {
            id: 1,
            icon: <FcAssistant size={75} />,
            label: "Service Client",
            description: "Disponible 24h/24 et 7j/7"
        },
        {
            id: 2,
            icon: <FcPhoneAndroid size={75} />,
            label: "Nous Contacter",
            description: "+221 775 372 893"
        },
    ]

    return (
        <div className={`bg-secondary px-6 md:px-[120px] py-20 ${style.background}`}>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                {
                    lists?.map(item => {
                        return <div
                            key={item.id} className='flex gap-4 bg-white -skew-x12 skew-y-0 py-6 px-4 rounded-md animation duration-300 hover:translate-y-2'>
                            <div>
                                {item.icon}
                            </div>
                            <div className='flex flex-col gap-3'>
                                <span>{item.label}</span>
                                <span className='font-[300]'>{item.description}</span>
                            </div>
                        </div>
                    })
                }

            </div>
        </div>
    )
}

export default Features