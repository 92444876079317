// @ts-ignore 
// @ts-nocheck
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { appConfig } from '../../../contants/configurations';
import Dashboardlayout from '../../../layouts/Dashboardlayout';
import axiosInstance from "../../../utils/axiosInstance";

let emptyUser = {
    id: '',
    name: '',
    marque: '',
}

function ContractList() {

    const [selectedEmploye, setSelectedEmploye] = useState<[] | null>(null);
    // @ts-ignore
    const [rowClick, setRowClick] = useState<boolean>(true);
    const toast = useRef<Toast>(null)
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const navigate = useNavigate();
    const { isLoading, error, data, refetch } = useQuery('fetchContract', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/contract`);
        return data.data;
    })

    if (error) {
        return <span>Error ...</span>
    }

    const confirmDelete = (id: string) => {
        Swal.fire({
            title: 'Voulez-vous vraiment le supprimer ?',
            text: "Cette action est irreversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`${appConfig.API_URL}/contract/${id}`).then(response => {
                    Swal.fire(
                        'Supprimé!',
                        response.data.message,
                        'success'
                    )
                    refetch()
                });
            }
        })

    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };
        // @ts-ignore
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // Toolbar
    // --- Left side
    const startContent = (
        <React.Fragment>
            <Button label="Nouveau" size='small' icon="pi pi-plus" onClick={() => navigate('/contrat/add')} className="mr-2 bg-primarydeep h-10 border-none" />
            <Button label="Supprimer" size='small' icon="pi pi-trash" className='h-10' severity="danger" disabled={!selectedEmploye || !selectedEmploye.length} />
        </React.Fragment>
    );

    const endContent = (
        <React.Fragment>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} className='text-sm' placeholder="Rechercher..." />
            </span>
        </React.Fragment>
    );

    const actionButtons = (RowData: any) => {
        return <div>
            <Button icon="pi pi-print" rounded outlined className="mr-2" onClick={() => navigate(`/contrat/generate/${RowData._id}`)} />
            <Button icon="pi pi-eye" rounded outlined className="mr-2" onClick={() => navigate(`/contrat/edit/${RowData._id}`)} />
            <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(RowData._id)}
            />
        </div>
    }


    return (
        <Dashboardlayout title="Liste des Contrats">
            <div className='h-full'>
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="shadow bg-white rounded-lg p-8 flex flex-col gap-3">
                    <Toolbar start={startContent} end={endContent} />
                    {
                        isLoading ? <ProgressSpinner /> :
                            <DataTable value={data} selectionMode={rowClick ? undefined : 'multiple'} size='small'
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                paginator
                                rows={5}
                                dataKey="_id"
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                filters={filters} filterDisplay="menu"
                                globalFilterFields={['nom', 'prenom', 'prix_jour', 'status']}
                                emptyMessage="Aucun contrat disponibles."
                                tableStyle={{ minWidth: '40rem' }}>
                                <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
                                <Column field="nom" sortable header="Nom"></Column>
                                <Column field="prenom" sortable header="Prénom"></Column>
                                <Column field="prix_jour" sortable header="Prix/jour"></Column>
                                <Column field="depart" sortable header="Départ"></Column>
                                <Column field="retour" sortable header="Retour"></Column>
                                <Column header="Action" body={actionButtons}></Column>
                            </DataTable>
                    }
                </div>
            </div>
        </Dashboardlayout>
    )
}

export default ContractList