export const valueLabelFormat = (value: number) => {
    let fcfa = value;
    let euro = value / 650;

    if (fcfa <= 79000) {
        return `${fcfa} FCFA (${euro.toFixed(1)}€)`;
    } else {
        return "Peu importe"
    }
}

export const valueLabelFormatUL = (value: number) => {
    let fcfa = value;
    let euro = value / 650;
    return `${fcfa} FCFA (${euro.toFixed(1)}€)`;
}