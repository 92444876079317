// @ts-ignore 
// @ts-nocheck
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Slider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import React, { useState } from 'react';
import { VehicleSearchAttributes } from '../../pages/rental/RentalTypes';

const useStyles = makeStyles((theme: Theme) => ({
    sidebar: {
        display: "flex",
        flexDirection: "column",
        paddingInline: 15,
        borderRadius: 10,
        paddingTop: 30,
        paddingBottom: 15,
        backgroundColor: "#ffffff",
        boxShadow: "rgba(145, 158, 171, 0.2) 0px 3px 3px -2px, rgba(145, 158, 171, 0.14) 0px 3px 4px 0px, rgba(145, 158, 171, 0.12) 0px 1px 8px 0px",
    },

}));

const sideBarData = [
    {
        label: "TYPE DE VOITURE",
        options: [
            {
                label: "Citadine",
                checked: false
            },
            {
                label:'Mercedes',
                checked: false
            },
            {
                label:'Toyota',
                checked: false
            },
            {
                label: "Range Rover",
                checked: false
            },
            {
                label: "Cabriolet",
                checked: false
            },
            {
                label: "Carina",
                checked: false
            },
            {
                label: "Minibus - Bus",
                checked: false
            },
        ]
    },
    {
        label: "OPTIONS",
        options: [
            {
                label: "Climatisation",
                checked: false,
                value: 'climatisation'
            },
            {
                label: "Siège bébé",
                checked: false,
                value:'siege_bebe'
            },
            {
                label: "Audio bluetooth",
                checked: false,
                value:'audio_bluetooth'
            },
            {
                label: "GPS",
                checked: false,
                value:'gps'
            },
            {
                label: "Siège en cuir",
                checked: false,
                value:'siege_cuir'
            },
            {
                label: "Caméra de recul",
                checked: false,
                value:'camera'
            },
            {
                label: "Apple CarPlay",
                checked: false
            },
            {
                label: "Android Auto",
                checked: false,
                value:'regulateur'
            },
            {
                label: "Régulateur de vitesse",
                checked: false
            },
            {
                label: "Ports de recharge USB",
                checked: false,
                value:'recharge_usb'
            },
            {
                label: "Toit panoramique",
                checked: false,
                value:'toit'
            },
        ]
    },
    {
        label: "BOITE DE VITESSE",
        options: [
            {
                label: "Manuelle",
                checked: false
            },
            {
                label: "Automatique",
                checked: false
            },
        ]
    },
    {
        label: "MOTEUR",
        options: [
            {
                label: "Diesel",
                checked: false
            },
            {
                label: "Essence",
                checked: false
            },
            {
                label: "Hybride diesel",
                checked: false
            },
            {
                label: "Hybride essence",
                checked: false
            },
            {
                label: "Electrique",
                checked: false
            },
        ]
    },
];

const title = {
    fontWeight: 500,
    color: "#637381",
    fontSize: 14,
    marginBottom: 1,
}

function valueLabelFormat(value: number) {
    let fcfa = value;
    let euro = value / 650;

    if (fcfa <= 79000) {
        return `${fcfa} FCFA (${euro.toFixed(1)}€)`;
    } else {
        return "Peu importe"
    }
}

interface SideBarProps{
    setFilterQuery: React.Dispatch<React.SetStateAction<VehicleSearchAttributes>>
    filterQuery: VehicleSearchAttributes
}

function Sidebar(props: SideBarProps) {
    const classes = useStyles();

    const [price, setPrice] = useState<number>(10)
    const [kilometrage, setKilometrage] = useState<number>(10)
    const [age, setAge] = useState<number>(4);
    const [place, setPlace] = useState<number>(4);
    const [door, setDoor] = useState<boolean>(false);
    const [carecteristics,setCarecteristics] = useState<any>(sideBarData)

    const updateQueryString=(filterKey:any,value:any)=>{
        const key = getKey(filterKey)
        let options = carecteristics.filter((item:any)=> item.label === filterKey)[0]?.options
        let arrFilterQuery = props?.filterQuery;
        arrFilterQuery[key] = options.map((item)=> {
                    if(item.checked)
                    {
                        if(item.hasOwnProperty('value')){
                            return item?.value
                        }else{
                            return item?.label
                        }
                    }
                }).filter((item)=> item)

        props.setFilterQuery({...arrFilterQuery})
    }

    const getKey=(key:any)=>{
        const keys = Object.keys(props?.filterQuery)
        const myKey = keys.find((item,index)=>  key.toLowerCase().includes(item))
        return myKey ?? '';
    }
    
    const updateCarecteristicsCheck=(key:any,index:any)=>{
        let arr = [...carecteristics]
        arr.forEach((item)=>{
            if(item.label === key || item?.value === key){
                item.options[index].checked = !item?.options[index]?.checked 
            }
        })

        setCarecteristics([...arr])
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setPrice(newValue);
        }
    };


    const getFinalPriceChanged=(event: Event, finalValue: number | number[])=>{
        props.setFilterQuery({...props.filterQuery,prix: finalValue})
    }
    const ArrowIcon = () => {
        return <ExpandMoreIcon sx={{ fontSize: 20 }} />
    }
    return (
        <Box className={classes.sidebar}>

            {/* Prix Max  */}
            <Box>
                <Divider sx={{ marginBottom: 2 }} />
                <Typography id="non-linear-slider" gutterBottom sx={[title, { display: "flex", justifyContent: "space-between" }]}>
                    PRIX MAX <span style={{ textDecoration: "underline", color: "black" }}>{price && price <= 79000 && valueLabelFormat(price)}</span>
                </Typography>
                <Slider
                    aria-label="Small steps"
                    defaultValue={15000}
                    aria-labelledby="non-linear-slider"
                    valueLabelFormat={valueLabelFormat}
                    value={price}
                    onChange={handleChange}
                    onChangeCommitted={getFinalPriceChanged}
                    valueLabelDisplay="auto"
                    step={1000}
                    min={15000}
                    max={80000}
                    size='small'
                />
            </Box>
            {
                carecteristics && carecteristics.map((item:any, key:any) => {
                    return <>
                        <Accordion disableGutters sx={{ paddingTop: 1.3, paddingBottom: 1.3, backgroundColor: "#ffffff", }} elevation={0} key={key}>
                            <AccordionSummary
                                expandIcon={<ArrowIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={title}>{item.label}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    item.options && item.options.map((item2:any, index:any) => {
                                        return <FormGroup key={item2.label} sx={{ paddingInline: 3 }}>
                                            <Divider />
                                            <FormControlLabel control={<Checkbox checked={item2.checked} onChange={()=> {
                                                updateCarecteristicsCheck(item.label,index)
                                                updateQueryString(item.label,item2)
                                            }}/>} sx={{ paddingTop: 1, paddingBottom: 1 }} label={item2.label} />
                                        </FormGroup>
                                    })
                                }

                            </AccordionDetails>
                        </Accordion>
                        <Divider sx={{ opacity: 0.0 }} />
                    </>
                })
            }

            {/* Kilometrage  */}
            <Box>
                <Divider sx={{ marginBottom: 2 }} />
                <Typography id="non-linear-slider" gutterBottom sx={[title, { display: "flex", justifyContent: "space-between" }]}>
                    KILOMETRAGE MAX <span style={{ textDecoration: "underline", color: "black" }}>{kilometrage}</span>
                </Typography>
                <Slider
                    aria-label="Small steps"
                    defaultValue={1500}
                    aria-labelledby="non-linear-slider"
                    // valueLabelFormat={valueLabelFormat}
                    value={150}
                    // onChange={handleChange}
                    valueLabelDisplay="auto"
                    step={1000}
                    min={15000}
                    max={80000}
                    size='small'
                />
            </Box>

            {/* Age Max  */}
            {/* <Box>
                <Typography id="non-linear-slider" gutterBottom sx={[title, { display: "flex", justifyContent: "space-between" }]}>
                    ÂGE MAX <span style={{ textDecoration: "underline", color: "black" }}>{price && price <= 79000 && valueLabelFormat(price)}</span>
                </Typography>
                <Slider
                    aria-label="Small steps"
                    defaultValue={15000}
                    aria-labelledby="non-linear-slider"
                    valueLabelFormat={valueLabelFormat}
                    value={age}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    step={1000}
                    min={15000}
                    max={80000}
                    size='small'
                />
            </Box> */}

            {/* Nombre de place  */}
            {/* <Box>
                <Typography id="non-linear-slider" gutterBottom sx={[title, { display: "flex", justifyContent: "space-between" }]}>
                    NOMBRE DE PLACES MINIMUM <span style={{ textDecoration: "underline", color: "black" }}>{price && price <= 79000 && valueLabelFormat(price)}</span>
                </Typography>
                <Slider
                    aria-label="Small steps"
                    defaultValue={15000}
                    aria-labelledby="non-linear-slider"
                    valueLabelFormat={valueLabelFormat}
                    value={place}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    step={1000}
                    min={15000}
                    max={80000}
                    size='small'
                />
            </Box> */}
        </Box>
    )
}

export default Sidebar
