// @ts-nocheck
import { Icon } from "@iconify/react";
import axios from "axios";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";
import { useState } from "react";
import {
  FcCamera,
  FcTwoSmartphones,
  FcUsb,
  FcVip,
  FcWiFiLogo,
} from "react-icons/fc";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import climatisation from "../../assets/clim.svg";
import { appConfig } from "../../contants/configurations";
import Landinglayouts from "../../layouts/Landinglayouts";
import { valueLabelFormatUL } from "../../utils/PriceConversion";
// Import Swiper styles
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import IMAGES from "../../contants/images";

function RentalDetails() {
  const [visible, setVisible] = useState<boolean>(false);
  const [voiture, setVoiture] = useState<string>("");
  const [nom, setNom] = useState<string>("");
  const [prenom, setPrenom] = useState<string>("");
  const [itinairaire, setItinairaire] = useState<string>("");
  const [telephone, setTelephone] = useState<string>("");
  const [from, setFrom] = useState<string | Date | Date[] | null | undefined>(
    null
  );
  const [to, setTo] = useState<string | Date | Date[] | null | undefined>(null);
  const params = useParams();

  const { isLoading, error, data } = useQuery("vehicleDetails", async () => {
    const { data } = await axios(`${appConfig.API_URL}/voiture/${params.id}`);
    setVoiture(data.data._id);
    return data.data;
  });

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <ProgressSpinner />{" "}
      </div>
    );
  }

  if (error) {
    return <span>Error ...</span>;
  }

  const saveReservation = async (e) => {
    e.preventDefault();
    const data = {
      voiture: voiture,
      nom: nom,
      prenom: prenom,
      itinairaire: itinairaire,
      telephone: telephone,
      date_debut: from,
      date_fin: to,
    };
    await axios
      .post(`${appConfig.API_URL}/reservation`, data)
      .then((response) => {
        Swal.fire("Réservation Confirmer!", response.data.message, "success");
        setVisible(false);
      })
      .catch((error) => {
        Swal.fire("Sorry!", error.response.data.message, "error");
      });
  };

  return (
    <Landinglayouts title="Rental Details">
      <div className="px-6 md:px-[120px] mt-[90px] mb-[55px]">
        <div className={`flex gap-4`}>
          <div className="w-full md:w-[65%] flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <span className="font-semibold text-[16px] md:text-2xl">
                💎 Offre de Location de voiture{" "}
                {data.avec_chauffeur ? "avec chauffeur " : "sans chauffeur"} -{" "}
                {data.marque[0].name + " " + data.model[0].name} -{" "}
                {valueLabelFormatUL(data.prix)}/jour
              </span>
              <div className="flex items-center gap-1">
                <div>
                  <Icon icon="ic:baseline-star" fontSize={20} color="orange" />
                </div>
                <span className="font-medium">Nouveau</span>
              </div>
            </div>
            <div className="bg-gray-400 overflow-hidden h-full w-full md:w-full md:h-[400px] rounded-md px[30px]">
              <Swiper
                modules={[Navigation, Pagination]}
                pagination={{
                  clickable: true,
                }}
                spaceBetween={30}
                navigation={true}
                className="mySwiper h-full w-full"
              >
                {data.images ? (
                  data.images.map((item: any) => {
                    return (
                      <SwiperSlide key={item}>
                        <img
                          src={item}
                          alt={item}
                          className="w-full h-full animation relative object-cover cursor-pointer duration-300 hover:scale-110"
                        />
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <img
                    src={IMAGES.CAR}
                    alt="Chambres"
                    className="w-full h-full animation relative cursor-pointer duration-300 hover:scale-110"
                  />
                )}
              </Swiper>
            </div>

            <div className="flex flex-col justify-center gap-4">
              <div className="flex flex-col gap-1">
                <div className="text-[16px] lg:text-[22px] font-[600] uppercase">
                  À propos de la location du véhicule
                </div>
                <span className="mt-2">
                  Prix valable pour une location de plus de 5 jours à Dakar.
                </span>
                <span>
                  Pour moins de 5 jours, la location est de {data.prix}{" "}
                  FCFA/Jour.
                </span>
              </div>

              <hr className="text-gray-200 bg-gray-200" />
              {/* Characteristique */}
              <div className="flex flex-col justify-center gap-1">
                <div className="text-[16px] lg:text-[22px] font-[600] uppercase">
                  CARACTÉRISTIQUES TECHNIQUES
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-3">
                  {data.nombre_place ? (
                    <div className="flex flex-col items-start">
                      <Icon icon="mdi:car-seat" fontSize={15} />
                      <span className="text-[15px]">
                        {data.nombre_place} places
                      </span>
                    </div>
                  ) : null}
                  {data.nombre_porte ? (
                    <div className="flex flex-col items-start">
                      <Icon icon="mdi:car-door" fontSize={15} />
                      <span className="text-[15px]">
                        {data.nombre_porte} portes
                      </span>
                    </div>
                  ) : null}
                  {data.model ? (
                    <div className="flex flex-col items-start">
                      <Icon icon="mdi:cog-outline" fontSize={15} />
                      <span className="text-[15px]">{data.model[0].name}</span>
                    </div>
                  ) : null}
                  {data.kilometrage ? (
                    <div className="flex flex-col items-start">
                      <Icon icon="mdi:car-speed-limiter" fontSize={15} />
                      <span className="text-[15px]">{data.kilometrage} km</span>
                    </div>
                  ) : null}
                  {data.annee ? (
                    <div className="flex flex-col items-start">
                      <Icon icon="mdi:package-variant" fontSize={15} />
                      <span className="text-[15px]">{data.annee}</span>
                    </div>
                  ) : null}
                  {data.moteur ? (
                    <div className="flex flex-col items-start">
                      <Icon icon="game-icons:gas-pump" fontSize={15} />
                      <span className="text-[15px]">{data.moteur}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <hr className="text-gray-200 bg-gray-200" />
              {/* Options */}
              <div className="flex flex-col justify-center gap-1">
                <div className="text-[16px] lg:text-[22px] font-[600] uppercase">
                  OPTIONS ET ACCESSOIRES
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 my-3">
                  {data.options.climatisation && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <img src={climatisation} alt="" />
                      <span>Climatisation</span>
                    </div>
                  )}
                  {data.options.siege_bebe && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <Icon icon="mdi:car-seat" fontSize={15} />
                      <span>Siège bébé</span>
                    </div>
                  )}
                  {data.options.siege_cuir && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <Icon icon="mdi:car-seat" fontSize={15} />
                      <span>Siège en Cuir</span>
                    </div>
                  )}
                  {data.options.audio_bluetooth && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <FcWiFiLogo />
                      <span>Audio bluetooth</span>
                    </div>
                  )}
                  {data.options.gps && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <FcTwoSmartphones />
                      <span>GPS</span>
                    </div>
                  )}
                  {data.options.camera && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <FcCamera />
                      <span>Camera</span>
                    </div>
                  )}
                  {data.options.regulateur && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <FcVip />
                      <span>Regulateur</span>
                    </div>
                  )}
                  {data.options.recharge_usb && (
                    <div className="bg-gray-200 p-2 rounded-full max-w-fit px-3 flex gap-2 items-center justify-center">
                      <FcUsb />
                      <span>Recharge Usb</span>
                    </div>
                  )}
                </div>
              </div>
              <hr className="text-gray-200 bg-gray-200" />
              <div>
                <span className="text-[14px] md:text-[22px] font-medium">
                  AVIS
                </span>
                <div className="flex items-center gap-1">
                  <Icon icon="ic:baseline-star" fontSize={20} color="orange" />
                  <span className="text-[16px] font-medium"> Nouveau</span>
                </div>
              </div>
              <div className="md:hidden">
                <button
                  type="button"
                  onClick={() => setVisible(true)}
                  className="w-full bg-primary outline-none border-none hover:bg-primary/90 cursor-pointer rounded-md text-white py-4 px-6 text-sm"
                >
                  Valider votre réservation
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:inline-block p-4">
            <div
              className="border-2 border-black bg-white rounded-xl p-4 flex flex-col gap-6"
              style={{ border: 2, borderColor: "red" }}
            >
              <div className="flex flex-col gap-2">
                <h3>
                  {valueLabelFormatUL(data.prix)} <span>/jour</span>
                </h3>
                <span className=" text-gray-500 text-sm">
                  {data.nombre_place} Place(s) et {data.nombre_porte} porte(s)
                </span>
              </div>
              <hr />
              <div className="flex flex-col gap-4">
                <h2 className="font-semibold">Détails du prix</h2>
                <div className="flex flex-col gap-3">
                  <div className="flex justify-between">
                    <span className=" text-gray-400 text-sm">1 Jour</span>
                    <span className="font-medium text-sm">
                      {data.prix} FCFA
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className=" text-gray-400 text-sm">
                      19,25% de TVA
                    </span>
                    <span className="font-medium text-sm">Inclus</span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-col gap-4">
                <h2 className="font-semibold">Politique de remboursement</h2>
                <span className=" text-blue-500 text-sm">
                  Annulation gratuite jusqu'à 48h avant le check-in
                </span>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setVisible(true)}
                  className="w-full bg-secondary outline-none border-none hover:bg-secondary/90 cursor-pointer rounded-md text-white py-4 px-6 text-sm"
                >
                  Valider votre réservation
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ###### MODAL ###### */}
        {/* Modal To Make Reservation */}
        <Dialog
          header="Confirmer Votre Réservation"
          visible={visible}
          modal
          className="p-fluid"
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          onHide={() => setVisible(false)}
        >
          <div className="w-full">
            <form onSubmit={saveReservation}>
              {/* Nom et Prénom */}
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="nom" className="font-medium">
                    Nom *
                  </label>
                  <InputText
                    id="nom"
                    type="text"
                    value={nom}
                    placeholder="Entrer votre nom"
                    className="w-full"
                    required
                    onChange={(e) => setNom(e.target.value)}
                  />
                </div>

                <div className="w-full flex flex-col gap-">
                  <label htmlFor="prenom" className="font-medium">
                    Prénom *
                  </label>
                  <InputText
                    id="prenom"
                    type="text"
                    value={prenom}
                    className="w-full"
                    required
                    placeholder="Entrer votre prénom"
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </div>

                <div className="w-full flex flex-col gap-">
                  <label htmlFor="prenom" className="font-medium">
                    Itinairaire
                  </label>
                  <InputText
                    id="prenom"
                    type="text"
                    value={itinairaire}
                    className="w-full"
                    placeholder="Entrer votre destination"
                    onChange={(e) => setItinairaire(e.target.value)}
                  />
                </div>

                <div className="w-full flex flex-col gap-">
                  <PhoneInput
                    // country="sn"
                    // localization={"sn"}
                    specialLabel="Téléphone *"
                    inputStyle={{ width: "100%" }}
                    onChange={(val) => setTelephone(val)}
                    // onlyCountries={["sn"]}
                    enableAreaCodes={false}
                    disableDropdown={false}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: false,
                    }}
                  />
                </div>
              </div>

              {/* Date */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  marginTop: 16,
                }}
              >
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="from" className="font-medium">
                    Date de Début *
                  </label>
                  <Calendar
                    value={from}
                    placeholder="Date de Début"
                    required
                    minDate={new Date()}
                    onChange={(e: CalendarChangeEvent) => setFrom(e.value)}
                    showIcon
                  />
                </div>
                <div className="w-full flex flex-col gap-">
                  <label htmlFor="to" className="font-medium">
                    Date de Fin *
                  </label>
                  <Calendar
                    value={to}
                    placeholder="Date de Fin"
                    minDate={from}
                    style={{ color: "green", borderColor: "green" }}
                    required
                    panelStyle={{ color: "green", borderColor: "green" }}
                    onChange={(e: CalendarChangeEvent) => setTo(e.value)}
                    showIcon
                  />
                </div>
              </div>

              <div style={{ display: "flex", marginTop: 20, gap: 10 }}>
                <Button
                  label="Annuler"
                  style={{ color: "green", borderColor: "green" }}
                  icon="pi pi-times"
                  className="border-secondary"
                  outlined
                  onClick={() => setVisible(false)}
                />
                <Button style={{ backgroundColor: "green", borderColor: "green" }} label="Enregistrer" className="bg-secondary" type="submit" icon="pi pi-check" />
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    </Landinglayouts>
  );
}

export default RentalDetails;
