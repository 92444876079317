// @ts-ignore 
// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system/createTheme';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../assets/google.svg";
import LOGO_M from "../../assets/logo_m.svg";
import Footer from '../../partials/Footer/Footer';
// Other Login
// import Face from "react-facebook-login";
// import GoogleLogin from 'react-google-login';
import IMAGES from '../../contants/images';

function Loginchoose() {

    const { loginWithRedirect } = useAuth0();

    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            display: 'flex',
            flexDirection: "column",
            backgroundColor: "#ffffff",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 75,
            paddingInline: 10
        },
        form: {
            display: "flex",
            flexDirection: "column",
            maxWidth: 480,
            gap: 35,
            backgroundColor: "white",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            borderRadius: 16,
            padding: 56,
            paddingTop: 60,
            paddingBottom: 60,
        },
        header: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 7,
            "& h3": {
                fontSize: 26,
                fontWeight: 600
            },
            "& span": {
                fontSize: 14,
                color: "#637381"
            }
        },
        buttonsPart: {
            display: "flex",
            flexDirection: "column",
            fontSize: 12,
            gap: 25
        },
        divider: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 8,
            "& div": {
                width: "100%"
            },
            "& div hr": {
                opacity: 0.4
            },
        },
        footer: {
            fontSize: 12,
            color: "gray",
            textAlign: "center",
            "& a": {
                textDecoration: "underline"
            }
        }
    }))

    const button = {
        textTransform: "capitalize",
        color: "black",
        fontSize: 14,
        paddingY: 1.4
    }

    const classes = useStyles();

    const googleResponse = ({ response }: any) => {
        alert(response)
    }

    const facebookResponse = ({ response }: any) => {
        alert(response)
    }

    return (
        <>
            <div className={classes.root}>
                <Box sx={{
                    height: "100vh", display: "flex", flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <form className={classes.form}>
                        <Box className={classes.header}>
                            <Box sx={{
                                display: { xs: 'none', md: 'flex' }, marginTop: -2, width: 120
                            }}>
                                <img src={IMAGES.LOGO2} alt="Normale logo" width="100%" />
                            </Box>
                            <Box sx={{
                                display: { xs: 'flex', md: 'none' }, marginTop: -2, width: 50
                            }}>
                                <img src={LOGO_M} alt="Mobile logo" width="100%" />
                            </Box>
                            <h3>Bonjour et bienvenue</h3>
                            <span>Connectez-vous pour continuer</span>
                        </Box>
                        <Box className={classes.buttonsPart}>
                            {/* <FacebookLogin
                                appId="" //APP ID NOT CREATED YET
                                fields="name,email,picture"
                                callback={facebookResponse}
                            /> */}
                            <br />
                            <br />

                            {/* <GoogleLogin
                                clientId="814006274199-f2vqft8e2uliodbvetgi6ltthocbvc9a.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                                buttonText="LOGIN WITH GOOGLE"
                                onSuccess={googleResponse}
                                onFailure={googleResponse}
                            /> */}


                            <Button variant="outlined" startIcon={<GoogleIcon />} onClick={() => loginWithRedirect()} sx={button}>Continuer avec Google</Button>
                            <Button variant="outlined" startIcon={<FacebookIcon />} onClick={() => loginWithRedirect()} sx={button}>Continuer avec Facebook</Button>

                            <Box className={classes.divider}>
                                <div><hr /></div>
                                OU
                                <div><hr /></div>
                            </Box>
                            <Button variant="outlined" sx={button}>Compte STS</Button>
                        </Box>

                        <Box className={classes.footer}>
                            En me connectant, j'accepte les <Link to="/terms-of-service" target='_blank'>conditions générales d'utilisation</Link> et la <Link to="/privacy-policy" target='_blank'>politique de confidentialité</Link> de STS.
                        </Box>
                    </form>
                </Box>

            </div >
            <Footer /></>
    )
}

export default Loginchoose
