// @ts-ignore 
// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import SearchIcon from '@mui/icons-material/Search';
import { Button, Divider } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IMAGES from '../../contants/images';

const useStyles = makeStyles({
    navbar: {
        backdropFilter: 'blur(10px)'
    },
});


function Navbar() {
    const { logout, isAuthenticated, user } = useAuth0();

    const [email, setEmail] = useState<String | undefined>(user?.email);
    const classes = useStyles();

    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const listItem = {
        fontSize: 13,
        color: "black"
    }

    return (
        <AppBar position="fixed" color="transparent" sx={{ paddingY: 1.2, paddingInline: { xs: 1, md: 12 } }} elevation={0} tabIndex={1} className={classes.navbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' }, width: 200
                    }}>
                        <Link to="/" className='font-bold w-16 h-16 text-2xl tracking-wider'>
                            <img src={IMAGES.LOGO2} alt="Normale logo" width="100%" className='h-full w-full rounded-full' />
                        </Link>
                    </Box>
                    <Box sx={{
                        display: { xs: 'flex', md: 'none' }, width: 50, height: 50
                    }}>
                        <Link to="/">
                            <img src={IMAGES.LOGO2} alt="Mobile logo" width="100%" className='h-full w-full rounded-full' />
                        </Link>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: "center", gap: { xs: 2, md: 2 } }}>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            {/* This appears only on Small Screen */}
                            <Button variant="outlined" className="text-secondary" endIcon={<SearchIcon fontSize="large" className="text-secondary" />} sx={{ textTransform: "capitalize", fontSize: { xs: 10, md: 14 }, display: { xs: "inline-flex", md: "none" } }} onClick={() => navigate("/rental")}></Button>
                            {/* This appears only on Large Screen */}
                            <Button variant="outlined" className="text-secondary border-secondary" endIcon={<SearchIcon fontSize="large" />} sx={{ textTransform: "capitalize", fontSize: 14, display: { xs: "none", md: "inline-flex" } }} onClick={() => navigate("/rental")}>Rechercher</Button>
                            {
                                !isAuthenticated ? (
                                    <Button variant="contained" className="bg-secondary" onClick={() => navigate("/login")} sx={{ textTransform: "capitalize", fontSize: { xs: 10, md: 14 } }}>Se connecter</Button>
                                ) :
                                    (
                                        <Box sx={{ flexGrow: 0 }}>
                                            <Tooltip title="">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar alt="SM" src="/static/images/avatar/2.jpg" />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                elevation={2}
                                                sx={{ mt: '50px' }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.12))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}

                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                <MenuItem sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                    <Typography sx={{ fontSize: 14, color: "black", fontWeight: 500 }}>{user?.name}</Typography>
                                                    <Typography sx={{ fontSize: 13, color: "gray" }}>{`${user?.email && user?.email.substring(0, 20)}${email && email.length > 20 ? "..." : ""}`}</Typography>
                                                </MenuItem>
                                                <Divider sx={{ borderStyle: 'dashed' }} />
                                                <MenuItem onClick={handleCloseUserMenu}>
                                                    <Typography sx={listItem}>Accueil</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseUserMenu}>
                                                    <Typography textAlign="center" sx={listItem}>Mes Annonces</Typography>
                                                </MenuItem>
                                                <Divider sx={{ borderStyle: 'dashed' }} />

                                                {
                                                    isAuthenticated && <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} ><Typography sx={listItem}>Se déconnecter</Typography></MenuItem>
                                                }
                                            </Menu>
                                        </Box>
                                    )
                            }
                        </Box>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar >
    );
}
export default Navbar;