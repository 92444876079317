import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";
import IMAGES from "../../contants/images";


function CarCard({ item }: any) {    
const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = IMAGES.CAR
  }, []);

  return (
    <motion.a
      whileInView={{ y: [150, 0], opacity: [0, 1] }}
      transition={{
        duration: 0.6,
        ease: "easeIn",
      }}
      href={`/rental/${item._id}`}
      key={item._id}
      className="bg-white inline-block shadow-2xl rounded-md overflow-hidden cursor-pointer"
    >
      <div className="overflow-hidden w-full h-56 relative" style={{ display: imageLoaded ? "none" : "inline" }}>
      <Blurhash
            hash="LkLN}f4pM^D}o_J.r=wI~VR}IXV}"
            width="100%"
            height={224}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
      </div>
      <div className="overflow-hidden w-full h-56 relative">
        <img
          loading="lazy"
          src={item.images[0]}
          alt="Voiture"
          style={{
            height: "100%",
            width: "100%",
            display: !imageLoaded ? "none" : "inline",
          }}
          className="animation w-full h-full object-cover duration-300 hover:scale-110"
        />
      </div>
      <div className="p-4 flex flex-col gap-4">
        <div className="flex flex-col">
          <h4 className="text-[1.3rem] font-semibold text-primary">
            {item.marque[0].name} {item.model[0].name} - {item.annee}
          </h4>
          <div className="text-sm text-primary">
            <span className="font-semibold">{item.prix} XAF</span>
            <span>/jour</span>
          </div>
          <span className="text-sm text-gray-500">
            {item.quartier} {item.ville}
          </span>
        </div>
        <div className="mt-2">
          <div>
            <div className="flex justify-between text-base">
              <span className="text-gray-500 text-sm no-underline">
                Transmission
              </span>
              <span className="font-semibold text-primary text-[14px]">
                {item.transmission}
              </span>
            </div>
            <div className="flex justify-between text-base">
              <span className="text-gray-500 text-sm">Moteur</span>
              <span className="font-semibold text-primary text-[14px] no-underline">
                {item.moteur}
              </span>
            </div>
          </div>
          <div>
            <hr className="bg-gray-400 h-[1.6px] mt-2 outline-none border-none" />
          </div>
        </div>
      </div>
    </motion.a>
  );
}

export default CarCard;
