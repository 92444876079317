
// @ts-nocheck
import { FcApproval, FcAutomotive, FcBusinessman } from "react-icons/fc";
import style from "./style.module.css";

function Howitwork() {
    const clients = [
        {
            title: "Créer un Compte",
            icon: <FcBusinessman size={70} />,
            description: "créer un compte afin de profiter pleinement des fonctionnalités et des avantages offerts par une plateforme en ligne, en échange de la fourniture d'informations personnelles et de l'acceptation des règles et des conditions de service"
        },
        {
            title: "Rechercher un Véhicule",
            icon: <FcAutomotive size={70} />,
            description: "Notre site web est conçu pour faciliter votre recherche de véhicule en vous offrant une plateforme conviviale et efficace. Que vous planifiez des vacances, un voyage d'affaires ou une escapade de dernière minute, notre site vous permettra de trouver l'option d'hébergement qui correspond le mieux à vos besoins et à votre budget"
        },
        {
            title: "Faites une Réservation",
            icon: <FcApproval size={70} />,
            description: "Faites des réservations en ligne de manière pratique et rapide. Que vous souhaitiez planifier un dîner romantique, réserver une chambre d'hôtel pour vos vacances, acheter des billets de spectacle ou organiser une sortie en famille, notre plateforme vous facilitera la tâche"
        },
    ];

    return (
        <>
            <div className={`flex flex-col px-4 md:px-[120px] py-20 gap-16 bg-slate ${style.background}`}>
                <div className='flex flex-col items-center gap-2'>
                    <h2 className='text-primary font-semibold text-xl md:text-3xl lg:text-4xl'>Comment ça fonctionne ?</h2>
                    <span className='text-gray-700 uppercase'>En tant que Client</span>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-8'>
                    {
                        clients.map((item, key) => {
                            return <div key={key} className='flex flex-col gap-4 bg-white relative shadow-2xl rounded-md p-6 py-12 animation duration-200 hover:translate-y-2 cursor-pointer overflow-hidden border-white'>
                                <div className='absolute h-12 w-20 top-2 -left-2 rounded-r-full bg-secondary flex items-center justify-center text-white'>
                                    <span className='text-xl'>{key + 1}</span>
                                </div>
                                <div className='flex flex-col gap-2 relative items-center'>
                                    {item.icon}
                                    <div className=' font-semibold'>{item.title}</div>
                                </div>
                                <div className='flex flex-col text-center'>
                                    <span className='text-sm font-normal'>{item.description.substring(0, 90)} ...</span>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Howitwork