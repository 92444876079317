// @ts-ignore 
// @ts-nocheck
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadUploadEvent,
  ItemTemplateOptions,
} from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { appConfig } from "../../../contants/configurations";
import Dashboardlayout from "../../../layouts/Dashboardlayout";
import axiosInstance from "../../../utils/axiosInstance";

function Caredit() {
  const params = useParams();

  let emptyCar = {
    marque: null,
    model: null,
    transmission: "",
    kilometrage: 0,
    immatriculation: "TD",
    images: [],
    annee: "",
    moteur: "",
    avec_chauffeur: false,
    nombre_porte: 4,
    nombre_place: 2,
    prix: 0,
    climatisation: false,
    siege_bebe: false,
    siege_cuir: false,
    audio_bluetooth: false,
    gps: false,
    camera: false,
    regulateur: false,
    recharge_usb: false,
  };

  const [formValues, setFormValues] = useState(emptyCar);

  const { isLoading, error, data, isSuccess } = useQuery(
    "vehicleDetails",
    async () => {
      const { data } = await axiosInstance.get(`${appConfig.API_URL}/voiture/${params.id}`)
      let result = data.data;
      reset({
        immatriculation: result?.immatriculation,
        kilometrage: result?.kilometrage,
        nombre_porte: result?.nombre_porte,
        nombre_place: result?.nombre_place,
        marque: result?.marque[0].name,
        model: result?.model[0].name,
        transmission: result?.transmission,
        annee: result?.annee,
        prix: result?.prix,
        gps: result?.options?.gps,
        moteur: result?.moteur,
        climatisation: result?.options?.climatisation,
        camera: result?.options?.camera,
        avec_chauffeur: result?.options?.avec_chauffeur,
        audio_bluetooth: result?.options?.audio_bluetooth,
        recharge_usb: result?.options?.recharge_usb,
        regulateur: result?.options?.regulateur,
        siege_bebe: result?.options?.siege_bebe,
        siege_cuir: result?.options?.siege_cuir,
        })
      return data.data
    }
  );

  const marques = useQuery("fetchMarques", async () => {
    const { data } = await axiosInstance.get(`${appConfig.API_URL}/marques`);
    return data.data;
  });

  const model = useQuery("fetchModels", async () => {
    const { data } = await axiosInstance.get(`${appConfig.API_URL}/model`);
    return data.data;
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ formValues });

  useEffect(() => {

    (async function (){
        reset({
            immatriculation: result?.immatriculation,
            kilometrage: result?.kilometrage,
            nombre_porte: result?.nombre_porte,
            nombre_place: result?.nombre_place,
            marque: result?.marque[0].name,
            model: result?.model[0].name,
            transmission: result?.transmission,
            annee: result?.annee,
            prix: result?.prix,
            gps: result?.options?.gps,
            moteur: result?.moteur,
            climatisation: result?.options?.climatisation,
            camera: result?.options?.camera,
            avec_chauffeur: result?.options?.avec_chauffeur,
            audio_bluetooth: result?.options?.audio_bluetooth,
            recharge_usb: result?.options?.recharge_usb,
            regulateur: result?.options?.regulateur,
            siege_bebe: result?.options?.siege_bebe,
            siege_cuir: result?.options?.siege_cuir,
            })
    })()
    
      setFormValues({
        ...formValues,
        immatriculation: data?.immatriculation,
        kilometrage: data?.kilometrage,
        nombre_porte: data?.nombre_porte,
        nombre_place: data?.nombre_place,
        marque: data?.marque[0].name,
        model: data?.model[0].name,
        transmission: data?.transmission,
        annee: data?.annee,
        prix: data?.prix,
        moteur: data?.moteur,
        gps: data?.options?.gps,
        climatisation: data?.options?.climatisation,
        camera: data?.options?.camera,
        avec_chauffeur: data?.options?.avec_chauffeur,
        audio_bluetooth: data?.options?.audio_bluetooth,
        recharge_usb: data?.options?.recharge_usb,
        regulateur: data?.options?.regulateur,
        siege_bebe: data?.options?.siege_bebe,
        siege_cuir: data?.options?.siege_cuir,
      });
  }, [reset]);

  const chooseYesNo = [
    {
      label: "Oui",
      value: true,
    },
    {
      label: "Non",
      value: false,
    },
  ];

  const years = [
    { year: 2023 },
    { year: 2022 },
    { year: 2021 },
    { year: 2020 },
    { year: 2019 },
    { year: 2018 },
    { year: 2017 },
    { year: 2016 },
    { year: 2015 },
    { year: 2014 },
    { year: 2013 },
    { year: 2012 },
    { year: 2011 },
    { year: 2010 },
    { year: 2009 },
    { year: 2008 },
    { year: 2007 },
    { year: 2006 },
    { year: 2005 },
    { year: 2004 },
    { year: 2003 },
    { year: 2002 },
    { year: 2001 },
    { year: 2000 },
    { year: 1999 },
    { year: 1998 },
    { "": 1997 },
    { year: 1996 },
    { year: 1995 },
    { year: 1994 },
    { year: 1993 },
    { year: 1992 },
    { year: 1991 },
    { year: 1990 },
  ];

  const handleCategoryAddSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCategoryAddInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const toast = useRef<Toast>(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);

  const onTemplateSelect = (e: FileUploadUploadEvent) => {
    let _totalSize = totalSize;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
      _totalSize += files[i].size || 0;
    }

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e: FileUploadUploadEvent) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current?.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const onTemplateRemove = (file: File, callback: Function) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 7 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const saveVoiture = async (data) => {
    // alert(JSON.stringify(formValues))
    // alert(JSON.stringify(data))
    // e.preventDefault();
    if (formValues.images.length <= 0) {
      return Swal.fire(
        "Formulaire Incomplèt!",
        "Veuillez charger au-moins une image",
        "error"
      );
    }

    const formData = new FormData();
    formData.append("marque", data.marque);
    formData.append("model", data.model);
    formData.append("transmission", data.transmission);
    formData.append("kilometrage", data.kilometrage);
    formData.append("immatriculation", data.immatriculation);
    formData.append("annee", data.annee);
    formData.append("moteur", data.moteur);
    formData.append("avec_chauffeur", formValues.avec_chauffeur);
    formData.append("nombre_porte", formValues.nombre_porte);
    formData.append("nombre_place", formValues.nombre_place);
    formData.append("prix", data.prix);
    formData.append("climatisation", formValues.climatisation);
    formData.append("siege_bebe", formValues.siege_bebe);
    formData.append("siege_cuir", formValues.siege_cuir);
    formData.append("audio_bluetooth", formValues.audio_bluetooth);
    formData.append("gps", formValues.gps);
    formData.append("camera", formValues.camera);
    formData.append("regulateur", formValues.regulateur);
    formData.append("recharge_usb", formValues.recharge_usb);

    for (let i = 0; i < formValues.images.length; i++) {
      let file = formValues.images[i];
      formData.append("images", file);
    }

    await axios
      .post(`${appConfig.API_URL}/voiture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          type: "formData",
        },
      })
      .then((response) => {
        toast.current?.show({
          severity: "success",
          summary: "Enregistré",
          detail: response.data.message,
          life: 8000,
        });
        setFormValues(emptyCar);
      })
      .catch((error) => {
        Swal.fire("Sorry!", error.response.data.message, "error");
      });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const customBase64Uploader = async (event: FileUploadHandlerEvent) => {
    // convert file to base64 encoded
    const images = [];
    const file = event.files;

    for (let i = 0; i < file.length; i++) {
      images.push(file[i]);
    }
    setFormValues({ ...formValues, images: images });
  };

  return (
    <Dashboardlayout title="Modifier les Informations">
      <div className="h-full">
        <Toast ref={toast} />
        {isSuccess && data?.options && (
          <>
            <form
              onSubmit={handleSubmit(saveVoiture)}
              className="flex flex-col p-4 gap-6 border-2 border-green-700 rounded-sm bg-white"
              encType="multipart/form-data"
            >
              {/* Marques & Modèles */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="marque"
                    control={control}
                    rules={{ required: "Marque is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={
                            "font-medium" +
                            classNames({ "p-error": errors.value })
                          }
                        >
                          Marque *
                        </label>
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          editable
                          optionLabel="name"
                          optionValue="_id"
                          placeholder="Selectionner"
                          focusInputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                          className={
                            'w-full md:w-14rem"' +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          options={marques.data}
                        />
                        {getFormErrorMessage("marque")}
                      </>
                    )}
                  />
                </div>
                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="model"
                    control={control}
                    rules={{ required: "Modèle is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={
                            "font-medium" +
                            classNames({ "p-error": errors.value })
                          }
                        >
                          Modèle *
                        </label>
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          optionLabel="name"
                          editable
                          optionValue="_id"
                          placeholder="Selectionner"
                          focusInputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                          className={
                            'w-full md:w-14rem"' +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          options={model.data}
                        />
                        {getFormErrorMessage("model")}
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Moteur & Transmission */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="moteur"
                    control={control}
                    rules={{ required: "Moteur is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={
                            "font-medium" +
                            classNames({ "p-error": errors.value })
                          }
                        >
                          Moteur *
                        </label>
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          optionLabel="name"
                          optionValue="name"
                          editable
                          placeholder="Selectionner"
                          focusInputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                          className={
                            'w-full md:w-14rem"' +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          options={[
                            { id: 1, name: "Diesel" },
                            { id: 2, name: "Essence" },
                            { id: 3, name: "Hybride diesel" },
                            { id: 4, name: "Hybride essence" },
                            { id: 5, name: "Electrique" },
                          ]}
                        />
                        {getFormErrorMessage("moteur")}
                      </>
                    )}
                  />
                </div>

                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="transmission"
                    control={control}
                    rules={{ required: "Transmission is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={
                            "font-medium" +
                            classNames({ "p-error": errors.value })
                          }
                        >
                          Transmission *
                        </label>
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          optionLabel="name"
                          optionValue="name"
                          editable
                          placeholder="Selectionner"
                          focusInputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                          className={
                            'w-full md:w-14rem"' +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          options={[
                            { id: 1, name: "Automatique" },
                            { id: 2, name: "Manuelle" },
                          ]}
                        />
                        {getFormErrorMessage("transmission")}
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Avec Chauffeur ? */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="avec_chauffeur" className="font-medium">
                    Avec Chauffeur ?
                  </label>
                  <Dropdown
                    value={formValues.avec_chauffeur}
                    options={chooseYesNo}
                    name="avec_chauffeur"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>

              {/* Portes & Places */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="nombre_porte" className="font-medium">
                    Nombre de Portes *
                  </label>
                  <InputText
                    type="number"
                    required
                    name="nombre_porte"
                    defaultValue={formValues.nombre_porte}
                    value={formValues.nombre_porte}
                    onChange={handleCategoryAddInput}
                  />
                </div>
                <div className="w-full flex flex-col gap-2">
                <Controller
                    name="nombre_place"
                    control={control}
                    rules={{ required: "Nombre de place is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className={"font-medium"}>
                          Nombres de Place *
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value}
                          className={
                            "w-full" +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />


                  {/* <label htmlFor="nombre_place" className="font-medium">
                    Nombre de Place *
                  </label>
                  <InputText
                    type="number"
                    required
                    name="nombre_place"
                    value={formValues.nombre_place}
                    onChange={handleCategoryAddInput}
                  /> */}
                </div>
              </div>

              {/* Immatriculation & Kilométrage */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="immatriculation"
                    control={control}
                    rules={{ required: "Immatriculation is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className={"font-medium"}>
                          Immatriculation *
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value}
                          className={
                            "w-full" +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>

                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="kilometrage"
                    control={control}
                    rules={{ required: "Kilométrage is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="font-medium">
                          Kilometrage *
                        </label>
                        <InputText
                          id={field.name}
                          type="number"
                          value={field.value}
                          className={
                            "w-full" +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Année & Prix */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="annee"
                    control={control}
                    rules={{ required: "Année is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={
                            "font-medium" +
                            classNames({ "p-error": errors.value })
                          }
                        >
                          Année *
                        </label>
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          optionLabel="year"
                          optionValue="year"
                          editable
                          placeholder="Selectionner"
                          focusInputRef={field.ref}
                          onChange={(e) => field.onChange(e.value)}
                          className={
                            'w-full md:w-14rem"' +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          options={years}
                        />
                        {getFormErrorMessage("annee")}
                      </>
                    )}
                  />
                </div>

                <div className="w-full flex flex-col gap-2">
                  <Controller
                    name="prix"
                    control={control}
                    rules={{ required: "Prix is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <label
                          htmlFor={field.name}
                          className={
                            "font-medium" +
                            classNames({ "p-error": errors.value })
                          }
                        >
                          Prix *
                        </label>
                        <InputText
                          id={field.name}
                          value={field.value}
                          type="number"
                          className={
                            "w-full" +
                            classNames({ "p-invalid": fieldState.error })
                          }
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </div>

              {/* Camera & Clim */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="camera" className="font-medium">
                    Camera
                  </label>
                  <Dropdown
                    editable
                    value={formValues.camera}
                    options={chooseYesNo}
                    name="camera"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="climatisation" className="font-medium">
                    Climatisation
                  </label>
                  <Dropdown
                    editable
                    value={formValues.climatisation}
                    options={chooseYesNo}
                    name="climatisation"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>

              {/* Siège bebe & cuir */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="siege_bebe" className="font-medium">
                    Siège bébé
                  </label>
                  <Dropdown
                    editable
                    value={formValues.siege_bebe}
                    options={chooseYesNo}
                    name="siege_bebe"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="siege_cuir" className="font-medium">
                    Siège en Cuir
                  </label>
                  <Dropdown
                    editable
                    value={formValues.siege_cuir}
                    options={chooseYesNo}
                    name="siege_cuir"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>

              {/* Bluetooth & GPS */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="audio_bluetooth" className="font-medium">
                    Audio bluetooth
                  </label>
                  <Dropdown
                    editable
                    value={formValues.audio_bluetooth}
                    options={chooseYesNo}
                    name="audio_bluetooth"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="gps" className="font-medium">
                    GPS
                  </label>
                  <Dropdown
                    editable
                    value={formValues.gps}
                    options={chooseYesNo}
                    name="gps"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>

              {/* Regulateur & USB */}
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="regulateur" className="font-medium">
                    Régulateur de vitesse
                  </label>
                  <Dropdown
                    editable
                    value={formValues.regulateur}
                    options={chooseYesNo}
                    name="regulateur"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
                <div className="w-full flex flex-col gap-2">
                  <label htmlFor="recharge_usb" className="font-medium">
                    Récharge USB
                  </label>
                  <Dropdown
                    editable
                    value={formValues.recharge_usb}
                    options={chooseYesNo}
                    name="recharge_usb"
                    onChange={handleCategoryAddSelect}
                    optionLabel="label"
                    placeholder="Selectionner"
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>

              {/* File Upload */}
              <div>
                <Tooltip
                  target=".custom-choose-btn"
                  content="Choose"
                  position="bottom"
                />
                <Tooltip
                  target=".custom-upload-btn"
                  content="Upload"
                  position="bottom"
                />
                <Tooltip
                  target=".custom-cancel-btn"
                  content="Clear"
                  position="bottom"
                />

                <FileUpload
                  ref={fileUploadRef}
                  name="images[]"
                  multiple
                  accept="image/*"
                  maxFileSize={1000000}
                  onUpload={onTemplateUpload}
                  onSelect={onTemplateSelect}
                  onError={onTemplateClear}
                  onClear={onTemplateClear}
                  headerTemplate={headerTemplate}
                  itemTemplate={itemTemplate}
                  emptyTemplate={emptyTemplate}
                  customUpload
                  uploadHandler={customBase64Uploader}
                  chooseOptions={chooseOptions}
                  uploadOptions={uploadOptions}
                  cancelOptions={cancelOptions}
                />
              </div>

              <div className="w-full flex gap-4 p-2 border-[1px] rounded-md mt-4">
                <Button
                  label="Réinitialiser"
                  icon="pi pi-times"
                  className="w-full"
                  severity="secondary"
                  type="reset"
                  raised
                />
                <Button
                  label="Enregistrer"
                  icon="pi pi-check"
                  className="w-full"
                  severity="info"
                  type="submit"
                  raised
                />
              </div>
            </form>
          </>
        )}
      </div>
    </Dashboardlayout>
  );
}

export default Caredit;
