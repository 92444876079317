// @ts-ignore 
// @ts-nocheck
import { Icon } from "@iconify/react";
import { Autocomplete, Box, Button, Container, Step, StepButton, Stepper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system/createTheme';
import React, { useState } from 'react';
import Landinglayouts from '../../layouts/Landinglayouts';


const steps = ['Marque et modèle', 'Caractéristiques techniques', 'Kilométrage et âge', 'Options', 'Photos', 'Prestations proposées sur cette voiture'];

const villes = [
    { title: 'Dakar' },
    { title: 'Mbour-Saly' },
    { title: 'Cap Skirring' },
    { title: 'Thiès' },
    { title: 'Touba' },
    { title: 'Saint Louis' },
    { title: 'Diourbel' },
    { title: 'Kaolack' },
    { title: 'Ziguinchor' },
    { title: 'Kédougou' },
    { title: 'Tambacounda' },
    { title: 'Matam' },
    { title: 'Louga' },
    { title: 'Kolda' },
    { title: 'Séhdiou' },
    { title: 'Kaffrine' },
];

function RentCar() {
    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            display: 'flex',
            flexDirection: "column",
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 75,
            paddingInline: 10,
            width: "100%"
        },
        form: {
            display: "flex",
            flexDirection: "column",
            minWidth: 680,
            gap: 25,
            backgroundColor: "white",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            borderRadius: 16,
            padding: 56,
            paddingTop: 60,
            paddingBottom: 60,
        },
        header: {
            display: "flex",
            flexDirection: "column",
            gap: 0,
        },
        buttonsPart: {
            display: "flex",
            flexDirection: "column",
            fontSize: 12,
            gap: 25
        },
        divider: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 8,
            "& div": {
                width: "100%"
            },
            "& div hr": {
                opacity: 0.4
            },
        },
        footer: {
            fontSize: 12,
            color: "gray",
            textAlign: "center",
            "& a": {
                textDecoration: "underline"
            }
        }
    }))

    const classes = useStyles();

    const [ville, setVille] = useState<string | null>('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <Landinglayouts title='Rent My Car'>

            <Container maxWidth="lg" sx={{ marginTop: 15, marginBottom: 15 }}>
                <Box sx={{ width: '100%' }}>
                    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    <Typography sx={{ display: { xs: "none", md: "block" } }}>{label}</Typography>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {allStepsCompleted() ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>

                                    {/*  */}
                                    {/*  */}

                                    <div className={classes.root}>
                                        <Box sx={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}>
                                            <form className={classes.form}>
                                                <Box className={classes.header}>
                                                    <Typography sx={{ color: "#637381" }}>Choisir ma voiture</Typography>
                                                    <Typography sx={{ fontSize: { md: 22 }, fontWeight: 600 }}>Quel est le modèle de votre voiture ?</Typography>
                                                </Box>

                                                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        value={ville ? ville : ''}
                                                        onChange={(event: any, newValue: string | null) => {
                                                            // setVille(newValue);
                                                        }}
                                                        options={villes.map((option) => option.title)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Marque*"

                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search'
                                                                }}
                                                            />
                                                        )}

                                                    />

                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        value={ville ? ville : ''}
                                                        onChange={(event: any, newValue: string | null) => {
                                                            // setVille(newValue);
                                                        }}
                                                        options={villes.map((option) => option.title)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Modèle*"

                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search'
                                                                }}
                                                            />
                                                        )}

                                                    />
                                                </Box>

                                                <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                                                    <Box>
                                                        {
                                                            activeStep !== 0 ? <Button
                                                                color="primary"
                                                                variant='text'
                                                                disabled={activeStep === 0}
                                                                onClick={handleBack}
                                                                sx={{ mr: 1 }}
                                                            >
                                                                Précédant
                                                            </Button> : null
                                                        }

                                                        <Button variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                                                            Suivant <Icon icon="mdi:chevron-right" fontSize={20} color='white' />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </form>
                                        </Box>

                                    </div >
                                    {/*  */}
                                    {/*  */}
                                </Box>
                            </React.Fragment>
                        )}
                    </div>
                </Box>
            </Container>
        </Landinglayouts>
    )
}

export default RentCar