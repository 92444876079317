// @ts-ignore 
// @ts-nocheck
// import IMAGES from "../../../configurations/images"
// import { Navbar, Dropdown, Avatar } from "flowbite-react";
// import { logoutUser, selectCurrentUser } from "../../../features/auth/authSlice";
// import { ToastContainer, toast } from "react-toastify";
// import IMAGES from './../../../configuration/images';

interface Props {
    title: string
}

function Navbardashboard({ title }: Props) {
    // const dispatch = useDispatch();
    // const { name, email, isAuthenticated } = useSelector(selectCurrentUser);
    // const navigate = useNavigate();
    // const location = useLocation();

    // useEffect(() => {
    //     if (location.state && location.state.signin) {
    //         toast.success("Connexion avec succès")
    //         location.state.signin = false
    //     }
    // }, [location.state])


    // const handleLogOut = () => {
    //     dispatch(logoutUser());
    //     navigate("/login")
    // }

    // useEffect(() => {
    //     if (!isAuthenticated && !window.localStorage.getItem("accessToken")) navigate("/login");
    //     // if (window.localStorage.getItem("accessToken")) navigate('/dashboard/admin')
    // }, [isAuthenticated])

    return (
        <>
            {/* <ToastContainer autoClose={2000} /> */}
            <nav className="bg-white border-gray-200 shadow-sm px-7 py-5">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-aut">
                    <div className="flex items-center">
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-black uppercase">{title}</span>
                    </div>
                    <div className="flex items-center md:order-2">
                        <button type="button" className="flex text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                            <span className="sr-only">User Menu</span>
                            <img src="https://cdn-icons-png.flaticon.com/512/219/219969.png" className="h-8" alt="Flowbite Logo" />
                        </button>
                        <div className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="user-dropdown">
                            <div className="px-4 py-3">
                                <span className="block text-sm text-gray-900 dark:text-white">Sergeo Moffo</span>
                                <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">email@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbardashboard