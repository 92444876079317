import { ReactNode } from "react";

interface PhoneInterface {
    phone: String,
    children?: ReactNode
}

interface EmailInterface {
    email: String,
    children?: ReactNode
}

const Phonelink = ({ phone, children }: PhoneInterface) => {
    return <a href={`tel:${phone}`}>{children ? children : null}</a>;
};



const Maillink = ({ email, children }: EmailInterface) => {
    return <a href={`mailto:${email}`}>{children ? children : null}</a>;
};

export { Phonelink, Maillink }